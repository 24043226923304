import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

import TextInput from "../../components/Input/TextInput";
import InputError from "../../components/InputError";
import NonFieldError from "../../components/NonFieldError";
import { useEmailConfirmation } from "../../hooks/queries";
import Button from "../../components/Button";
import logo from "url:../../assets/cbl_logo.png";
import loginBackground from "url:../../assets/login_background.png";
import Loader from "../../components/Loader";
import Label from "../../components/Label";

const schema = Yup.object({
  confirmation_token: Yup.string().required("This field is required"),
  password: Yup.string().required("This field is required"),
  password_2: Yup.string().required("This field is required"),
});

const EmailConfirmation = ({ loggedIn }) => {
  const { t } = useTranslation();
  const { token } = useParams();
  const emailConfirmation = useEmailConfirmation();
  const [emailConfirmationError, setEmailConfirmationError] = useState();
  const [successfulConfirmation, setSuccessfulConfirmation] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);

  // If the token exists, redirect to the HomePage
  if (loggedIn || emailConfirmed) {
    return <Redirect to="/" />;
  }

  let pageContent = successfulConfirmation ? (
    <div className="flex flex-col m-auto items-center">
      <Label className="text-5xl text-green-500 font-noto font-semibold mb-4">
        {t("emailConfirmation.success")}
      </Label>
      <Label className="text-lg font-noto font-semibold mb-8">
        {t("emailConfirmation.successMessage")}
      </Label>
      <Loader className="m-auto" />
    </div>
  ) : (
    <Formik
      initialValues={{
        confirmation_token: token,
        password: "",
        password_2: "",
      }}
      onSubmit={async (values, actions) => {
        await emailConfirmation
          .mutateAsync(values)
          .then((response) => {
            setEmailConfirmationError(null);
            setSuccessfulConfirmation(true);
          })
          .catch((error) => {
            actions.setErrors(error.response.data);
            error.response.data.non_field_errors
              ? setEmailConfirmationError(error.response.data.non_field_errors)
              : setEmailConfirmationError(null);
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
      validationSchema={schema}
      data-test="email-confirmation-form"
    >
      <Form className="container w-90 m-auto" data-test="login-component">
        <div className="flex flex-col bg-transparent">
          <img
            className="justify-start object-contain h-8 w-22 mb-8"
            src={logo}
          />
          <h1 className="font-bold text-xl mb-8">
            {t("emailConfirmation.confirmAccount")}
          </h1>
          <div className="container flex flex-col space-y-1 mb-4">
            <TextInput
              label={t("emailConfirmation.newPassword")}
              name="password"
              backgroundColor="white"
              password
            />
            <InputError name="password" />
            <TextInput
              label={t("emailConfirmation.confirmNewPassword")}
              name="password_2"
              backgroundColor="white"
              password
            />
            <InputError name="password_2" />
            {emailConfirmationError && (
              <NonFieldError errorMessage={emailConfirmationError} />
            )}
          </div>
          <div className="flex justify-end mt-8">
            <Button type="submit" id="confirmAccount">
              {t("emailConfirmation.confirmButton")}
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );

  if (successfulConfirmation) {
    setTimeout(() => {
      setEmailConfirmed(true);
    }, 2000);
  }

  return (
    <div className="flex flex-row flex-grow bg-wf-100">
      <div
        className="bg-black max-h-screen bg-opacity-50 bg-cover w-login"
        style={{
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45) ), url("${loginBackground}")`,
        }}
      />
      {pageContent}
    </div>
  );
};

export default EmailConfirmation;
