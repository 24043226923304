import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../components/Container";
import DataTable from "../../components/DataTable";
import EmptyState from "../../components/EmptyState";
import Error from "../../components/Error";
import FilterBar from "../../components/FilterBar";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import partNumbersEmptyStateImage from "url:../../assets/part_numbers_empty_state.png";
import { useTogglable } from "../../hooks";
import PartNumberCreationWizard from "../PartNumberCreationWizard";
import { useData } from "./hooks";

const PartNumbersList = ({ user, selectedPartNumber }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [create, setCreate] = useState(true);
  const [search, setSearch] = useState("");
  const [project, setProject] = useState("");
  const [status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [orderingField, setOrderingField] = useState("");
  const modal = useTogglable();

  useEffect(() => {
    if (selectedPartNumber?.id) {
      setCreate(false);
      modal.open();
    }
  }, [selectedPartNumber?.id]);

  const showWizard = (partNumber = null) => {
    if (partNumber?.id) {
      history.push(`/part-numbers/${partNumber.id}`);
    } else {
      setCreate(true);
    }
    modal.open();
  };

  const { data, isLoading, error, columns, pageCount, projects } = useData(
    {
      user,
      queryParams: {
        page: currentPage,
        search,
        project,
        status,
        ordering: orderingField,
      },
    },
    showWizard
  );

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      <Breadcrumbs>
        <div className="py-4 text-xl font-semibold text-wf-900">
          {t("partNumbers.pageTitle")}
        </div>
      </Breadcrumbs>
      {ReactDOM.createPortal(
        <Modal isOpen={modal.isOpen}>
          <PartNumberCreationWizard
            setCreate={setCreate}
            selectedPartNumber={create ? null : selectedPartNumber}
            closeWizard={modal.close}
          />
        </Modal>,
        document.body
      )}
      <Container>
        {search === "" &&
        project === "" &&
        status === "" &&
        (data.length === 0 || data.length === undefined) ? (
          <EmptyState
            image={partNumbersEmptyStateImage}
            label={t("partNumbers.emptyStateLabel")}
            buttonLabel={t("partNumbers.newPartNumberButton")}
            buttonAction={showWizard}
          />
        ) : (
          <>
            <FilterBar
              onSearch={setSearch}
              search={search}
              onCreate={showWizard}
              buttonLabel={t("partNumbers.newPartNumberButton")}
              selects={[
                {
                  value: project,
                  onChange: setProject,
                  options: projects,
                },
                {
                  value: status,
                  onChange: setStatus,
                  options: [
                    { value: "DR", label: t("partNumberStatus.draft") },
                    { value: "PU", label: t("partNumberStatus.published") },
                  ],
                },
              ]}
            />
            <DataTable
              data={data}
              columns={columns}
              pageCount={pageCount}
              setNextPage={() => setCurrentPage(currentPage + 1)}
              setPreviousPage={() => setCurrentPage(currentPage - 1)}
              setPage={setCurrentPage}
              orderBy={setOrderingField}
              dataTest="part-numbers-table"
            />
          </>
        )}
      </Container>
    </>
  );
};

export default PartNumbersList;
