import React from "react";
import { HexColorPicker } from "react-colorful";

import { useOnClickOutside, useTogglable } from "../../hooks";

const PopoverPicker = (props) => {
  const modal = useTogglable();
  const { ref: popover } = useOnClickOutside(modal.close);

  const onChange = (value) => {
    props.form.setFieldValue(props.field.name, value);
  };

  return (
    <div className="mt-10 ml-4">
      <div
        className="cursor-pointer w-7 h-7 border-2 shadow rounded-full"
        style={{ backgroundColor: props.field.value }}
        onClick={modal.open}
      />

      {modal.isOpen && (
        <div className="absolute" ref={popover}>
          <HexColorPicker color={props.field.value} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

export default PopoverPicker;
