import React, { useEffect, useRef } from "react";
import T from "prop-types";

const CropPointsDelimiters = ({
  cropPoints: {
    lt: { position: point1 },
    rt: { position: point2 },
    rb: { position: point3 },
    lb: { position: point4 },
  },
  width,
  height,
}) => {
  const canvas = useRef();

  // Draw delimiter anytime the points change
  useEffect(() => {
    if (canvas.current) {
      const ctx = canvas.current.getContext("2d");
      ctx.clearRect(0, 0, width, height);
      ctx.lineWidth = 3;
      ctx.strokeStyle = "#3cabe2";

      ctx.beginPath();

      ctx.moveTo(point1.x, point1.y);
      ctx.lineTo(point2.x, point2.y);
      ctx.lineTo(point3.x, point3.y);
      ctx.lineTo(point4.x, point4.y);
      ctx.lineTo(point1.x, point1.y);

      ctx.stroke();
    }
  }, [
    point1.x,
    point1.y,
    point2.x,
    point2.y,
    point3.x,
    point3.y,
    point4.x,
    point4.y,
    canvas.current,
  ]);

  return (
    <canvas
      ref={canvas}
      className="absolute z-50"
      width={width}
      height={height}
    />
  );
};

export default CropPointsDelimiters;

CropPointsDelimiters.propTypes = {
  previewDims: T.shape({
    ratio: T.number,
    width: T.number,
    height: T.number,
  }),
  cropPoints: T.shape({
    lt: T.shape({ position: { x: T.number, y: T.number } }).isRequired,
    rt: T.shape({ position: { x: T.number, y: T.number } }).isRequired,
    rb: T.shape({ position: { x: T.number, y: T.number } }).isRequired,
    lb: T.shape({ position: { x: T.number, y: T.number } }).isRequired,
  }),
  pointSize: T.number,
};
