import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { HeaderLink } from "../HeaderLink";
import UserAvatar from "../UserAvatar";
import { useOnClickOutside, useToken } from "../../hooks";
import { useUsers, useProjects, usePartNumbers } from "../../hooks/queries";

const OmnisearchResults = ({
  projects,
  partNumbers,
  selectProject,
  selectPartNumber,
  onClose,
}) => {
  const { t } = useTranslation();
  const { ref } = useOnClickOutside(onClose);
  return (
    <div
      ref={ref}
      className="absolute w-full top-14 bg-white overflow-y-scroll h-38"
    >
      <div className="text-wf-800 p-3 font-semibold">
        {t("omniSearch.projects")}
      </div>
      {projects.map((p) => (
        <div
          key={p.id}
          className="py-1 px-3 flex flex-row items-center hover:bg-wf-200 cursor-pointer"
          onClick={() => selectProject(p.id)}
        >
          <div
            style={{ backgroundColor: p.color }}
            className="rounded-full w-2 h-2 shadow"
          />
          <div className="p-3 text-wf-900">{p.name}</div>
        </div>
      ))}
      <div className="border-wf-200 border" />
      <div className="text-wf-800 p-3 font-semibold">
        {t("omniSearch.partNumbers")}
      </div>
      {partNumbers.map((pn) => (
        <div
          key={pn.id}
          onClick={() => {
            selectPartNumber(pn.id);
            onClose();
          }}
          className="flex flex-col py-1 px-3 hover:bg-wf-200 cursor-pointer"
        >
          <div className="text-wf-900">{pn.name}</div>
          <div className="text-wf-700">{pn.code}</div>
        </div>
      ))}
    </div>
  );
};

/*
 * Header component.
 * Shows a minimal header if the user is not logged in.
 * Shows only relevant links when the user is logged in.
 */
const Header = ({ user, logout }) => {
  const { t } = useTranslation();
  const { removeToken } = useToken();
  const clientQuery = useQueryClient();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [projects, setProjects] = useState([]);
  const [partNumbers, setPartNumbers] = useState([]);
  const partNumbersQuery = usePartNumbers(
    { search },
    { enabled: search !== "" }
  );
  const projectsQuery = useProjects({ search }, { enabled: search !== "" });

  useEffect(() => {
    setProjects(projectsQuery.data?.data.results || []);
    setPartNumbers(partNumbersQuery.data?.data.results || []);
  }, [projectsQuery.data?.data.results, partNumbersQuery.data?.data.results]);

  useEffect(() => {
    if (search === "") {
      setPartNumbers([]);
      setProjects([]);
    }
  }, [search]);

  const doLogout = () => {
    logout.mutate(
      {},
      {
        onSuccess: () => {
          removeToken();
          clientQuery.invalidateQueries("meData");
          history.go("/");
        },
      }
    );
  };

  const isAdminOrBackoffice =
    user?.role && (user.role === "ADM" || user.role === "BCK");

  return (
    <header
      className="
        sticky top-0 z-40 bg-wf-900 flex flex-initial
        justify-between text-white px-34 text-sm
      "
      data-test="header-component"
    >
      <div className="flex">
        <div className="text-base font-bold py-4 mr-4">
          {t("header.cblBackoffice")}
        </div>
        <div className="flex" data-test="header-tabs">
          <div className="border-l border-wf-700 my-3.5 mx-4 w-px" />
          <HeaderLink to="/part-numbers" data-test="part-numbers-tab">
            {t("header.partNumbers")}
          </HeaderLink>
          {isAdminOrBackoffice && (
            <>
              <HeaderLink to="/users" data-test="users-tab">
                {t("header.users")}
              </HeaderLink>
              <HeaderLink to="/projects" data-test="projects-tab">
                {t("header.projects")}
              </HeaderLink>
            </>
          )}
        </div>
      </div>
      <div className="flex">
        <div className="flex mr-8">
          <div className="flex items-center w-65 bg-wf-800 relative">
            <form method="GET">
              <span className="flex text-wf-400">
                <button
                  type="submit"
                  className="pl-3.5 focus:outline-none focus:shadow-outline"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    className="w-4 h-4"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </button>
                <input
                  type="search"
                  name="q"
                  className="text-wf-400 text-sm bg-transparent focus:outline-none ml-2.5"
                  placeholder="Search"
                  autoComplete="off"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search !== "" && (
                  <OmnisearchResults
                    partNumbers={partNumbers}
                    selectPartNumber={(id) =>
                      history.push(`/part-numbers/${id}`)
                    }
                    projects={projects}
                    selectProject={(id) => history.push(`/projects/${id}`)}
                    onClose={() => setSearch("")}
                  />
                )}
              </span>
            </form>
          </div>
        </div>
        <UserAvatar user={user} doLogout={doLogout} />
      </div>
    </header>
  );
};

export default Header;
