import React from "react";

/*
 * Container for pages.
 */
const Container = (props) => (
  <div className="container min-w-full flex-col flex-auto justify-center py-8 px-34 bg-wf-100">
    {props.children}
  </div>
);

export default Container;
