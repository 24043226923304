import React from "react";
import { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  useProjects,
  useProjectDelete,
  useProjectsSearch,
} from "../../hooks/queries";
import { useTogglable } from "../../hooks";

import DeleteModal from "../../components/DeleteModal";
import trashCan from "url:../../assets/trash_can.svg";
import { USER_ROLES } from "../../utils";

/*
 * Hook used to retrieve all info about projects.
 */
export const useData = ({
  withPagination = true,
  queryParams,
  user,
  setProject,
}) => {
  const response = withPagination
    ? useProjects(queryParams)
    : useProjectsSearch(queryParams);
  const { t } = useTranslation();
  const data = withPagination
    ? response.data?.data.results || []
    : response.data?.data;
  const [pageCount, setPageCount] = useState(
    Math.ceil(response.data?.data.count / 10)
  );
  useEffect(() => {
    setPageCount(Math.ceil(response.data?.data.count / 10));
  }, [response.data?.data.count]);

  const columns = useMemo(() => [
    {
      Header: t("projectsList.header.name"),
      accessor: "name",
      Cell: ({ value, row }) => (
        <div
          className="flex flex-row items-center cursor-pointer text-wf-blue font-bold"
          onClick={() => setProject(row.original)}
        >
          <div
            style={{ backgroundColor: row.original.color }}
            className="rounded-full w-2 h-2 mr-3 shadow"
          />
          {value}
        </div>
      ),
    },
    {
      Header: t("projectsList.header.parts"),
      accessor: "parts",
      Cell: ({ value }) => {
        if (value.length === 0) {
          return <>{t("projectsList.emptyPartsMessage")}</>;
        }
        const parts = value.reduce(
          (result, name, i) =>
            result.concat(i === value.length - 1 ? name : `${name}, `),
          ""
        );
        return <>{parts}</>;
      },
    },
    {
      Header: t("projectsList.header.team"),
      accessor: "team",
      Cell: ({ value }) => {
        if (value.length === 0) {
          return <>{t("projectsList.emptyTeamMessage")}</>;
        }
        const users = value.reduce(
          (result, user, i) =>
            result.concat(
              i === value.length - 1 ? user.first_name : `${user.first_name}, `
            ),
          ""
        );
        return <>{users}</>;
      },
    },
    {
      id: "deleteColumn",
      Header: (_) => null,
      Cell: ({ row }) => {
        const modal = useTogglable();
        const userDelete = useProjectDelete(row.original.id);

        const handleDeleteAction = () => {
          userDelete
            .mutateAsync()
            .then(modal.close)
            .catch((error) => console.log(error.response.data));
        };

        if (user.role == USER_ROLES.operator) {
          return null;
        }

        return (
          <>
            <DeleteModal
              isOpen={modal.isOpen}
              closeModal={modal.close}
              deleteAction={handleDeleteAction}
              objectType={t("project")}
              objectName={row.original.name}
            />
            <button onClick={modal.open} className="w-10 p-0">
              <img className="trashCan" src={trashCan} />
            </button>
          </>
        );
      },
    },
  ]);

  return { ...response, data, columns, pageCount };
};
