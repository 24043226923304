import React from "react";
import PropTypes from "prop-types";

/**
 * @param {string} size - The size of the text. One of ["small", "medium", "large"]
 */
const Label = ({ size, ...props }) => {
  let sizeClass = "";
  switch (size) {
    case "small":
      sizeClass = "text-xs";
      break;
    case "medium":
      sizeClass = "text-base";
      break;
    case "large":
      sizeClass = "text-xl";
  }

  return (
    <div className={`font-noto text-wf-900 ${sizeClass}`} {...props}>
      {props.children}
    </div>
  );
};

Label.defaulProps = {
  size: "medium",
};

Label.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

export default Label;
