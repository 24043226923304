import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";

import Label from "../Label";
import dragNDropIcon from "url:../../assets/drag_n_drop.svg";
import infoIcon from "url:../../assets/info_icon.svg";

const ImageFileSelector = ({ onFileSelected }) => {
  const { t } = useTranslation();
  const onDrop = useCallback((acceptedFiles) => {
    for (let file of acceptedFiles) {
      onFileSelected(file);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
    accept: "image/png, image/jpeg",
  });

  return (
    <div className="flex flex-col px-8 py-12 mx-auto max-w-md whitespace-normal">
      <Label className="text-sm text-wf-800 font-noto font-semibold">
        {t(`partNumbers.wizard.imageUploadForm.dragNDropTitle`)}
      </Label>
      <Label className="text-sm text-wf-700 font-noto my-2">
        {t(`partNumbers.wizard.imageUploadForm.dragNDropDescription`)}
      </Label>
      <div
        {...getRootProps()}
        className="border-dashed border-2 border-wf-blue bg-wf-lightBlue py-4 px-8 space-y-2.5"
      >
        {/* TODO: check why the input needs an empty string or undefined */}
        <input value={undefined} {...getInputProps()} />
        <img src={dragNDropIcon} />
        <Label className="text-sm text-wf-mildBlue font-noto font-semibold">
          {t(`partNumbers.wizard.imageUploadForm.dragNDropHint`)}
        </Label>
      </div>
      <div className="max-w-md">
        <div className="inline-flex items-center mt-9">
          <img className="w-3.5 h-3.5 mr-2" src={infoIcon} />
          <Label className="text-sm text-wf-700 font-noto">
            {t(`partNumbers.wizard.imageUploadForm.dragNDropTip`)}
          </Label>
        </div>
        <ul className="text-wf-700 list-disc list-outside pl-6">
          <li>
            <Label className="text-sm text-wf-700 font-noto whitespace-normal">
              {t(`partNumbers.wizard.imageUploadForm.dragNDropFirstTip`)}
            </Label>
          </li>
          <li>
            <Label className="text-sm text-wf-700 font-noto whitespace-normal">
              {t(`partNumbers.wizard.imageUploadForm.dragNDropSecondTip`)}
            </Label>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ImageFileSelector;
