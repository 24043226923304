import React, { useState, useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";

import Button from "../Button";
import Label from "../Label";
import dragNDropIcon from "url:../../assets/drag_n_drop.svg";
import infoIcon from "url:../../assets/info_icon.svg";
import closeIcon from "url:../../assets/close_icon.svg";
import {
  useWizardUpdatePartNumber,
  useUploadDesignFile,
  useParseDesignFile,
} from "../../hooks/queries";
import { downloadFile } from "../../utils";
import NonFieldError from "../NonFieldError";

const UploadArea = ({ fileToUpload, handleFileReset }) => {
  return (
    <div className="inline-flex items-center justify-between bg-wf-input py-4 pl-3 pr-5">
      <Label className="text-base text-wf-900 font-noto">
        {fileToUpload.name}
      </Label>
      <button onClick={handleFileReset}>
        <img className="w-4 h-4" src={closeIcon} />
      </button>
    </div>
  );
};

const FileArea = React.forwardRef(({ inputProps, ...props }, ref) => {
  const { t } = useTranslation();
  // console.log(inputProps, props);
  return (
    <div>
      <div
        {...props}
        className="border-dashed border-2 border-wf-blue bg-wf-lightBlue py-4 px-8 space-y-2.5"
      >
        <input {...inputProps} />
        <img src={dragNDropIcon} />
        <Label className="text-sm text-wf-mildBlue font-noto font-semibold">
          {t("partNumbers.wizard.designFileUploadForm.dragNDropHint")}
        </Label>
      </div>
      <div className="inline-flex items-center mt-9">
        <img className="w-3.5 h-3.5 mr-2" src={infoIcon} />
        <Label className="text-sm text-wf-700 font-noto">
          {t("partNumbers.wizard.designFileUploadForm.dragNDropTip")}
        </Label>
      </div>
    </div>
  );
});

const DesignFileUploadForm = ({
  partNumberID,
  selectedPartNumber,
  goToNextPage,
  closeWizard,
  wizardStep,
  wizardStepsCount,
  setWizardStepProgress,
  minimal,
}) => {
  const { t } = useTranslation();
  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileError, setFileError] = useState(null);
  const updatePartNumber = useWizardUpdatePartNumber(partNumberID);
  const uploadDesignFile = useUploadDesignFile(partNumberID);
  const parseDesignFile = useParseDesignFile(partNumberID);
  const onDrop = useCallback((acceptedFiles) => {
    for (let file of acceptedFiles) {
      setFileToUpload(file);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
    // x-zip-compressed and x-zip are needed for chrome on windows
    accept:
      "text/xml, application/zip, application/x-zip-compressed, application/x-zip",
  });

  useEffect(() => {
    setFileToUpload(null);
    if (selectedPartNumber?.ipc2581b) {
      downloadFile(selectedPartNumber.ipc2581b, "text/xml")
        .then(setFileToUpload)
        .catch(console.log);
    }
  }, []);

  const handleSubmit = async (onUploadCompleted) => {
    const updatePartNumberData = new FormData();
    updatePartNumberData.append("wizard_step", wizardStep);

    const uploadDesignFileData = new FormData();
    uploadDesignFileData.append("ipc2581b", fileToUpload);

    await uploadDesignFile
      .mutateAsync(uploadDesignFileData)
      .then(() => {
        return parseDesignFile.mutateAsync();
      })
      .then(() => {
        return updatePartNumber.mutateAsync(updatePartNumberData);
      })
      .then(() => {
        setWizardStepProgress(wizardStep + 1);
        onUploadCompleted();
      })
      .catch((error) => {
        if (error?.response?.data?.detail) {
          setFileError(error?.response?.data?.detail);
          console.log(error?.response?.data?.detail);
          return;
        }
        setFileError(t("partNumbers.wizard.genericError"));
        console.log(error);
      });
  };

  const handleFileReset = () => {
    setFileToUpload(null);
    setFileError(null);
  };

  return (
    <div className="flex mx-auto">
      <div className="container border border-wf-300">
        {!minimal && (
          <div className="flex border-b border-wf-300 px-8 py-4">
            <Label className="text-base text-wf-900 font-noto font-semibold">
              {t("partNumbers.wizard.designFileUploadForm.formTitle", {
                currentStep: wizardStep,
                wizardStepsCount: wizardStepsCount,
              })}
            </Label>
          </div>
        )}
        <div className="flex flex-col px-8 py-12">
          <Label className="text-sm text-wf-800 font-noto font-semibold">
            {t("partNumbers.wizard.designFileUploadForm.dragNDropTitle")}
          </Label>
          <Label className="text-sm text-wf-700 font-noto my-2">
            {t("partNumbers.wizard.designFileUploadForm.dragNDropDescription")}
          </Label>
          <div className="container flex flex-col space-y-1">
            {fileToUpload ? (
              <UploadArea
                fileToUpload={fileToUpload}
                handleFileReset={handleFileReset}
              />
            ) : (
              <FileArea {...getRootProps()} inputProps={getInputProps()} />
            )}
            {fileError && <NonFieldError errorMessage={fileError} />}
          </div>
        </div>
        <div className="flex justify-end bg-wf-100 border-t border-wf-300 h-20 px-8 py-4">
          {!minimal && (
            <button
              className="border-wf-900 border-2 px-3 mr-2"
              onClick={() => handleSubmit(closeWizard)}
            >
              {t("partNumbers.wizard.generalInformationForm.saveAsDraftButton")}
            </button>
          )}
          <Button onClick={() => handleSubmit(goToNextPage)}>
            {t(
              "partNumbers.wizard.generalInformationForm.saveAndProceedButton"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DesignFileUploadForm;
