import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Label from "../../components/Label";
import WizardProgressComponent from "../../components/WizardProgressComponent";
import { useTogglable } from "../../hooks";
import Modal from "../../components/Modal";
import backButtonIcon from "url:../../assets/back_arrow.svg";
import closeButtonIcon from "url:../../assets/close_icon.svg";
import ExitWizardModal from "../../components/ExitWizardModal";
import SuccessModal from "../../components/SuccessModal";
import PartNumberWizardPageController from "../../components/PartNumberWizardPageController";

const PartNumberCreationWizard = ({
  selectedPartNumber = null,
  closeWizard,
  setCreate,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  // Modals
  const exitWizardModal = useTogglable();
  const successModal = useTogglable();
  // Current wizard page
  const [currentPage, setCurrentPage] = useState(1);
  // Wizard step reached for this part number
  const [wizardStepProgress, setWizardStepProgress] = useState(0);
  // State to check if we are creating a new part number
  // or editing an existing one.
  const [partNumberID, setPartNumberID] = useState(
    selectedPartNumber ? selectedPartNumber.id : null
  );

  // On component mount check if we are editing or creating.
  // If partNumber is not null, also go to the right wizard step
  useEffect(() => {
    if (selectedPartNumber) {
      setWizardStepProgress(selectedPartNumber.wizard_step);
      setCurrentPage(selectedPartNumber.wizard_step + 1);
    }
  }, [selectedPartNumber, selectedPartNumber?.wizard_step]);

  return (
    <div className="flex border-wf-blue border-t-4">
      {currentPage > 1 && (
        <button
          className="absolute left-0 top-0 h-6 w-7.5 mt-8 ml-9"
          onClick={() => {
            setCurrentPage(currentPage - 1);
          }}
        >
          <img src={backButtonIcon} />
        </button>
      )}
      <button
        className="absolute right-0 top-0 h-5 w-5 mt-8 mr-9"
        onClick={exitWizardModal.open}
      >
        <img src={closeButtonIcon} />
      </button>
      <div className="flex flex-col pt-23 pb-41 whitespace-nowrap">
        {ReactDOM.createPortal(
          <Modal isOpen={exitWizardModal.isOpen}>
            <ExitWizardModal
              className="inline-flex bg-white shadow-2xl transform transition-all"
              closeModal={exitWizardModal.close}
              closeWizard={closeWizard}
            />
          </Modal>,
          document.body
        )}
        {ReactDOM.createPortal(
          <Modal isOpen={successModal.isOpen}>
            <SuccessModal
              className="inline-flex bg-white shadow-2xl transform transition-all"
              closeAction={() => {
                closeWizard();
                history.push(`/part-numbers/${partNumberID}/`);
              }}
            />
          </Modal>,
          document.body
        )}
        <Label className="flex m-auto whitespace-nowrap text-3xl font-noto font-semibold">
          {t("partNumbers.wizard.title")}
        </Label>
        <div className="flex flex-col container mt-10 m-auto">
          <div className="flex flex-row px-52">
            <WizardProgressComponent
              stepNumber={1}
              label="Overview"
              currentPage={currentPage}
              onClick={() => {
                setCurrentPage(1);
              }}
              disabled={false}
            />
            <WizardProgressComponent
              stepNumber={2}
              label="Design file"
              currentPage={currentPage}
              onClick={() => {
                setCurrentPage(2);
              }}
              disabled={wizardStepProgress < 1}
            />
            <WizardProgressComponent
              stepNumber={3}
              label="Top image"
              currentPage={currentPage}
              onClick={() => {
                setCurrentPage(3);
              }}
              disabled={wizardStepProgress < 2}
            />
            <WizardProgressComponent
              stepNumber={4}
              label="Bottom image"
              currentPage={currentPage}
              onClick={() => {
                setCurrentPage(4);
              }}
              disabled={wizardStepProgress < 3}
            />
            <WizardProgressComponent
              stepNumber={5}
              label="PCB Board info"
              currentPage={currentPage}
              onClick={() => {
                setCurrentPage(5);
              }}
              disabled={wizardStepProgress < 4}
            />
          </div>
          <div className="flex mt-16">
            <PartNumberWizardPageController
              setCreate={setCreate}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              partNumberID={partNumberID}
              selectedPartNumber={selectedPartNumber}
              setPartNumberID={setPartNumberID}
              closeWizard={closeWizard}
              onCreationComplete={() => successModal.open}
              setWizardStepProgress={setWizardStepProgress}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartNumberCreationWizard;
