import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useTogglable } from "../../hooks";
import {
  useProjectsSearch,
  usePartNumberDelete,
  usePartNumbers,
} from "../../hooks/queries";

import DeleteModal from "../../components/DeleteModal";
import Label from "../../components/Label";

import { getLocale, PART_NUMBER_STATUSES, USER_ROLES } from "../../utils";

import chevronUp from "url:../../assets/chevron_up.svg";
import chevronDown from "url:../../assets/chevron_down.svg";
import trashCan from "url:../../assets/trash_can.svg";

/*
 * Hook used to add an `expanded` property to each result.
 */
export const useData = ({ queryParams, user }, showWizard) => {
  const projectsResponse = useProjectsSearch();
  const response = usePartNumbers(queryParams);
  const { t } = useTranslation();
  const data = response.data?.data.results || [];
  const [pageCount, setPageCount] = useState(
    Math.ceil(response.data?.data.count / 10)
  );

  useEffect(() => {
    setPageCount(Math.ceil(response.data?.data.count / 10));
  }, [response.data?.data.count]);

  const locale = getLocale();

  const columns = useMemo(() => [
    {
      id: "expander",
      width: "20px",
      Header: (_) => null,
      Cell: ({ row }) => (
        <div
          data-test="row-expander"
          {...row.getToggleRowExpandedProps({
            style: {
              paddingLeft: `${row.depth * 1}rem`,
              width: "0.625rem",
              height: "0.375rem",
            },
          })}
        >
          <img
            className="w-2.5 h-1.5"
            src={row.isExpanded ? chevronUp : chevronDown}
          />
        </div>
      ),
    },
    {
      Header: t("partNumbers.header.name"),
      accessor: "name",
      Cell: ({ row, value }) => {
        if (value === null || value === undefined) {
          return null;
        }

        return (
          <button
            onClick={() => {
              showWizard(row.original);
            }}
          >
            <Label className="text-wf-blue font-bold">{value}</Label>
          </button>
        );
      },
    },
    { Header: t("partNumbers.header.code"), accessor: "code" },
    {
      Header: t("partNumbers.header.project"),
      accessor: "project",
      Cell: ({ value }) => {
        if (value === null || value === undefined) {
          return null;
        }
        return (
          <div className="flex flex-row items-center">
            <div
              style={{ backgroundColor: value.color }}
              className="rounded-full w-2 h-2 mr-3 shadow"
            />
            {value.name}
          </div>
        );
      },
    },
    { Header: t("partNumbers.header.description"), accessor: "description" },
    {
      Header: t("partNumbers.header.status"),
      accessor: "status",
      Cell: ({ value }) => {
        const colors =
          value === PART_NUMBER_STATUSES.draft
            ? "text-wf-800 bg-wf-200"
            : "text-wf-green bg-wf-lightGreen";
        const status =
          value === PART_NUMBER_STATUSES.draft
            ? t("partNumbers.statuses.draft")
            : t("partNumbers.statuses.published");
        return (
          <div>
            <div className={`flex px-4 py-2 rounded-full w-min ${colors}`}>
              {status}
            </div>
          </div>
        );
      },
    },
    {
      Header: t("partNumbers.header.latestUpdate"),
      accessor: "modified",
      Cell: ({ row }) => {
        const latestUpdate = Date.parse(row.original.modified);
        const relativeTimeInSeconds = (Date.now() - latestUpdate) / 1000;
        const relativeTimeFormat = new Intl.RelativeTimeFormat({
          locale,
          style: "narrow",
        });

        if (relativeTimeInSeconds < 3600) {
          const elapsedTimeInMinutes = Math.floor(relativeTimeInSeconds / 60);
          return (
            <div>
              {relativeTimeFormat.format(-elapsedTimeInMinutes, "minutes")}
            </div>
          );
        }

        if (relativeTimeInSeconds < 86400) {
          const elapsedTimeInHours = Math.floor(relativeTimeInSeconds / 3600);
          return (
            <div>{relativeTimeFormat.format(-elapsedTimeInHours, "hours")}</div>
          );
        }

        const dateString = new Date(row.original.modified).toDateString();
        return <div>{dateString}</div>;
      },
    },
    {
      id: "deleteColumn",
      Header: (_) => null,
      Cell: ({ row }) => {
        const modal = useTogglable();
        const partNumbersDelete = usePartNumberDelete(row.original.id);

        const handleDeleteAction = () => {
          partNumbersDelete
            .mutateAsync()
            .then(modal.close)
            .catch((error) => console.log(error.response.data));
        };

        if (user.role == USER_ROLES.operator) {
          return null;
        }

        return (
          <>
            <DeleteModal
              isOpen={modal.isOpen}
              closeModal={modal.close}
              deleteAction={handleDeleteAction}
              objectType={t("part number")}
              objectName={row.original.name}
            />
            <button onClick={modal.open} className="w-10 p-0">
              <img className="trashCan" src={trashCan} />
            </button>
          </>
        );
      },
    },
  ]);

  return {
    ...response,
    data,
    columns,
    pageCount,
    projects:
      projectsResponse.data?.data?.map((p) => ({
        value: p.id,
        label: p.name,
      })) || [],
  };
};
