import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
} from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import { BackofficeRoute, OperatorRoute } from "./components/RoleRoute";

import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordReset from "./pages/PasswordReset";
import PartNumbersList from "./pages/PartNumbersList";
import PartNumberDetail from "./pages/PartNumberDetail";
import WorkSessionDetail from "./pages/WorkSessionDetail";
import UsersList from "./pages/UsersList";
import UserProfile from "./pages/UserProfile";
import ProjectsList from "./pages/ProjectsList";

import { useToken } from "./hooks";
import { useMeData, useLogout, usePartNumberDetail } from "./hooks/queries";
import EmailConfirmation from "./pages/EmailConfirmation";

const PartNumberRoute = ({ user }) => {
  const { id } = useParams();
  const partNumberQuery = usePartNumberDetail(id, {
    enabled: id !== undefined,
  });
  if (partNumberQuery.isLoading) {
    return null;
  }

  const selectedPartNumber = id ? partNumberQuery.data?.data : undefined;

  if (id && selectedPartNumber && selectedPartNumber.wizard_step > 4) {
    return <PartNumberDetail />;
  } else {
    return (
      <PartNumbersList user={user} selectedPartNumber={selectedPartNumber} />
    );
  }
};

const App = () => {
  // First of all try to fetch user's data so
  // we know if the User is correctly logged in.
  const meData = useMeData();
  const logout = useLogout();
  const { getToken } = useToken();
  const loggedIn = getToken() && !meData.isLoading && !meData.error;
  const user = meData.data?.data;

  return (
    <Router>
      {loggedIn && <Header loggedIn={loggedIn} user={user} logout={logout} />}
      {meData.isLoading ? null : (
        <Switch>
          <Route path="/login" exact>
            <Login loggedIn={loggedIn} refetchUser={meData.refetch} />
          </Route>
          <Route path="/forgot-password" exact>
            <ForgotPassword />
          </Route>
          <Route path="/reset-password/:token" exact>
            <PasswordReset />
          </Route>
          <Route path="/confirm-email/:token" exact>
            <EmailConfirmation loggedIn={loggedIn} />
          </Route>
          <BackofficeRoute path="/users" meData={meData}>
            <UsersList user={user} />
          </BackofficeRoute>
          <BackofficeRoute
            path={["/projects/:id", "/projects"]}
            meData={meData}
          >
            <ProjectsList user={user} />
          </BackofficeRoute>
          <OperatorRoute
            path={["/part-numbers/:id", "/part-numbers"]}
            exact
            meData={meData}
          >
            <PartNumberRoute user={user} />
          </OperatorRoute>
          <OperatorRoute
            path="/part-numbers/:partNumberId/work-sessions/:id"
            meData={meData}
          >
            <WorkSessionDetail />
          </OperatorRoute>
          <OperatorRoute path="/" exact meData={meData}>
            <Redirect to="/part-numbers" />
          </OperatorRoute>
          <OperatorRoute path="/account" exact meData={meData}>
            <Redirect to="/account/profile" />
          </OperatorRoute>
          <OperatorRoute
            path={["/account/profile", "/account/password"]}
            meData={meData}
          >
            <UserProfile user={user} refetchUser={meData.refetch} />
          </OperatorRoute>
        </Switch>
      )}
      {loggedIn && <Footer />}
    </Router>
  );
};

export default App;
