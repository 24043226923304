import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Redirect, Link, useParams } from "react-router-dom";

import TextInput from "../../components/Input/TextInput";
import InputError from "../../components/InputError";
import NonFieldError from "../../components/NonFieldError";
import Button from "../../components/Button";
import Label from "../../components/Label";
import logo from "url:../../assets/cbl_logo.png";
import loginBackground from "url:../../assets/login_background.png";
import { usePasswordReset } from "../../hooks/queries";

const schema = Yup.object({
  confirmation_token: Yup.string().required("This field is required"),
  new_password1: Yup.string().required("This field is required"),
  new_password2: Yup.string().required("This field is required"),
});

const PasswordReset = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const passwordReset = usePasswordReset();
  const [operationCompleted, setOperationCompleted] = useState(false);
  const [genericError, setGenericError] = useState();

  if (operationCompleted) {
    return <Redirect to="/login" />;
  }

  let pageContent = (
    <>
      <h1 className="font-bold text-xl mb-4">{t("passwordReset.title")}</h1>
      <div className="container flex flex-col space-y-4 mb-4">
        <TextInput
          label={t("passwordReset.new_password1")}
          name="new_password1"
          backgroundColor="white"
          password
        />
        <InputError name="new_password1" />
        <TextInput
          label={t("passwordReset.new_password2")}
          name="new_password2"
          backgroundColor="white"
          password
        />
        <InputError name="new_password2" />
        {genericError && (
          <NonFieldError errorMessage={t("passwordReset.invalidToken")} />
        )}
      </div>
      <div className="flex flex-row justify-end items-center mt-4">
        <Button type="submit" id="confirmAccount">
          {t("passwordReset.confirmButton")}
        </Button>
      </div>
    </>
  );

  if (passwordReset.isSuccess) {
    pageContent = (
      <>
        <Label className="text-wf-900 text-base font-noto font-semibold mb-4">
          {t("passwordReset.successMessage")}
        </Label>
        <Label className="text-wf-blue text-base underline cursor-pointer font-noto mb-4">
          <Link to="/login">{t("passwordReset.goToLogin")}</Link>
        </Label>
      </>
    );

    setTimeout(() => {
      setOperationCompleted(true);
    }, 5000);
  }

  return (
    <div className="flex flex-row flex-grow bg-wf-100">
      <div
        className="bg-black max-h-screen bg-opacity-50 bg-cover w-login"
        style={{
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45) ), url("${loginBackground}")`,
        }}
      />
      <Formik
        initialValues={{
          confirmation_token: token,
          new_password1: "",
          new_password2: "",
        }}
        onSubmit={async (values, actions) => {
          await passwordReset
            .mutateAsync(values)
            .then(() => {
              setGenericError(null);
            })
            .catch((error) => {
              console.log(error.response);
              actions.setErrors(error.response.data);
              error.response.data.bad_request
                ? setGenericError(error.response.data.bad_request)
                : setGenericError(null);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={schema}
        data-test="password-reset-form"
      >
        <Form className="container w-90 m-auto" data-test="login-component">
          <div className="flex flex-col bg-transparent">
            <img
              className="justify-start object-contain h-8 w-22 mb-8"
              src={logo}
            />
            {pageContent}
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default PasswordReset;
