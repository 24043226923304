import { useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { AxiosContext } from "../utils/client";

const BASE_URL = process.env.API_URL;

const useGetDetail = (queryName, url, useQueryOptions, ID) => {
  const axios = useContext(AxiosContext);
  return useQuery(
    [queryName, ID],
    async () => await axios.get(`${BASE_URL}${url}${ID}`),
    useQueryOptions
  );
};

const useGetWithParams = (queryName, url, useQueryOptions, queryParams) => {
  const axios = useContext(AxiosContext);
  const encodedQueryParams = new URLSearchParams(queryParams).toString();
  return useQuery(
    [queryName, encodedQueryParams],
    async () => await axios.get(`${BASE_URL}${url}?${encodedQueryParams}`),
    useQueryOptions
  );
};

const usePost = (url, refetchQuery) => {
  const axios = useContext(AxiosContext);
  // Query client here is used to refetch related queries when a mutation
  // has occurred.
  const queryClient = useQueryClient();
  const options = refetchQuery
    ? { onSuccess: () => queryClient.invalidateQueries(refetchQuery) }
    : undefined;
  return useMutation(
    async (body) => await axios.post(`${BASE_URL}${url}`, body),
    options
  );
};

const usePatch = (url, refetchQuery) => {
  const axios = useContext(AxiosContext);
  // Query client here is used to refetch related queries when a mutation
  // has occurred.
  const queryClient = useQueryClient();
  const options = refetchQuery
    ? { onSuccess: () => queryClient.invalidateQueries(refetchQuery) }
    : undefined;
  return useMutation(
    async (body) => await axios.patch(`${BASE_URL}${url}`, body),
    options
  );
};

const useDelete = (url, refetchQuery) => {
  const axios = useContext(AxiosContext);
  // Query client here is used to refetch related queries when a mutation
  // has occurred.
  const queryClient = useQueryClient();
  const options = refetchQuery
    ? { onSuccess: () => queryClient.invalidateQueries(refetchQuery) }
    : undefined;
  return useMutation(
    async (body) => await axios.delete(`${BASE_URL}${url}`, body),
    options
  );
};

// GET queries
export const useWorkSessions = (queryParams, useQueryOptions) =>
  useGetWithParams(
    "workSessions",
    "/work-sessions/",
    { keepPreviousData: true, ...useQueryOptions },
    queryParams
  );

export const usePartNumbers = (queryParams, useQueryParams) =>
  useGetWithParams(
    "partNumbers",
    "/part-numbers/",
    { keepPreviousData: true, ...useQueryParams },
    queryParams
  );

export const usePartNumberDetail = (partNumberID, useQueryOptions) =>
  useGetDetail(
    "partNumbers",
    "/part-numbers/",
    { keepPreviousData: true, ...useQueryOptions },
    partNumberID
  );

export const useWorkSessionDetail = (workSessionID, useQueryOptions) =>
  useGetDetail(
    "workSessions",
    "/work-sessions/",
    { keepPreviousData: true, ...useQueryOptions },
    workSessionID
  );

export const useProjectDetail = (projectId, useQueryParams) =>
  useGetDetail(
    "projects",
    "/projects/",
    { keepPreviousData: true, ...useQueryParams },
    projectId
  );

export const useUsers = (queryParams, useQueryParams) =>
  useGetWithParams(
    "users",
    "/users/",
    { keepPreviousData: true, ...useQueryParams },
    queryParams
  );

export const useProjects = (queryParams, useQueryParams) =>
  useGetWithParams(
    "projects",
    "/projects/",
    { keepPreviousData: true, ...useQueryParams },
    queryParams
  );

export const useProjectsSearch = (queryParams) =>
  useGetWithParams(
    "projects",
    "/projects-search/",
    { keepPreviousData: true },
    queryParams
  );

export const useMeData = () =>
  useGetWithParams("meData", "/users/me/", {
    retry: 0,
    refetchOnWindowFocus: false,
  });

// POST queries
export const useLogin = () => usePost("/api-token-auth/");
export const useLogout = () => usePost("/users/logout/");
export const useCreateUser = () => usePost("/users/", "users");
export const usePasswordUpdate = () => usePost("/users/change_password/");
export const useCreateProject = () => usePost("/projects/", "projects");
export const useUpdateProject = (projectId) =>
  usePatch(`/projects/${projectId}/`, "projects");
export const useCreatePartNumber = () =>
  usePost("/part-numbers-wizard/", "partNumbers");
export const useUpdatePartNumber = (partNumberID) =>
  usePatch(`/part-numbers/${partNumberID}/`);
export const useWizardUpdatePartNumber = (partNumberID) =>
  usePatch(`/part-numbers-wizard/${partNumberID}/`);
export const useUploadDesignFile = (partNumberID) =>
  usePatch(`/part-numbers-wizard/${partNumberID}/upload_design_file/`);
export const useParseDesignFile = (partNumberID) =>
  usePost(`/part-numbers-wizard/${partNumberID}/parse_design_file/`);
export const useEmailConfirmation = () => usePost("/users/confirm_email/");
export const useSendPasswordResetLink = () =>
  usePost("/users/send_reset_password_link/");
export const usePasswordReset = () => usePost("/users/reset_password/");

// PATCH queries
export const useUserUpdate = (userID) => usePatch(`/users/${userID}/`);

// DELETE queries
export const usePartNumberDelete = (partNumberID) =>
  useDelete(`/part-numbers/${partNumberID}/`, "partNumbers");
export const useUserDelete = (userID) =>
  useDelete(`/users/${userID}/`, "users");
export const useProjectDelete = (projectID) =>
  useDelete(`/projects/${projectID}/`, "projects");
