import React from "react";

import { useTable } from "react-table";

const MiniTable = ({ columns, data, dataTest }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <>
      <table
        className="border w-full"
        {...getTableProps()}
        data-test={dataTest}
      >
        <thead className="divide-y">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="bg-wf-200 h-8 px-2 text-xs text-wf-800 font-noto font-semibold text-left"
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}
        <tbody className="bg-white" {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps();
            return (
              <React.Fragment key={key}>
                <tr {...rowProps}>
                  {row.cells.map((cell, index) => {
                    const textStyle =
                      index == 0 ? "text-wf-blue font-semibold" : "text-wf-900";
                    return (
                      <td
                        className={`border-t border-wf-200 text-xs ${textStyle} px-2 h-8`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default MiniTable;
