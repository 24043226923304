import React from "react";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";

import Button from "../Button";
import TextInput from "../Input/TextInput";
import InputError from "../InputError";
import RadioLabel from "../Input/RadioLabel";
import { useCreateUser } from "../../hooks/queries";

// Default initial values
const INITIAL_VALUES = {
  first_name: "",
  last_name: "",
  email: "",
  role: "ADM",
};

const schema = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Enter a valid email address")
    .required("Email is required"),
});

const UserForm = (props) => {
  const createUser = useCreateUser();

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={async (values, actions) => {
        await createUser
          .mutateAsync(values)
          .then(() => {
            actions.resetForm();
            props.closeModal();
          })
          .catch((error) => {
            actions.setErrors(error.response.data);
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
      validationSchema={schema}
      data-test="user-form"
    >
      <Form className="flex-col border-t-4 border-blue-600">
        <div className="flex-col space-y-4 p-8">
          <span className="text-xl font-semibold">New User</span>
          <TextInput className="w-119" label="First name" name="first_name" />
          <InputError name="first_name" />
          <TextInput className="w-119" label="Last name" name="last_name" />
          <InputError name="last_name" />
          <TextInput className="w-119" label="Email" name="email" />
          <InputError name="email" />
          <p className="text-xs text-gray-600 font-semibold py-1">Role</p>
          <div className="flex flex-row">
            <RadioLabel name="role" value="ADM" label="Admin" />
            <RadioLabel name="role" value="BCK" label="Backoffice" />
            <RadioLabel name="role" value="OPE" label="Operator" />
          </div>
          <InputError className="text-red-500" name="role" />
          {createUser.isError && (
            <div className="text-red-500 p-2 bg-red-50 my-2">
              {createUser.error.message}
            </div>
          )}
        </div>
        <div className="bg-gray-200 flex flex-row flex-wrap justify-end p-8 w-full">
          <Button secondary className="mr-2" onClick={props.closeModal}>
            Cancel
          </Button>
          <Button type="submit">Create new user</Button>
        </div>
      </Form>
    </Formik>
  );
};

export default UserForm;
