import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useToken } from "../../hooks";

// ROLES
const OPERATOR = "OPE";
const ADMIN = "ADM";
const BACKOFFICE = "BCK";

const RoleRoute = ({ roles, meData, ...props }) => {
  const { getToken, removeToken } = useToken();
  const token = getToken();
  if (token && meData.error) {
    removeToken();
    return <Redirect to="/login" />;
  }
  if (!token || meData.error || !meData.data) {
    return <Redirect to="/login" />;
  }
  if (!roles.includes(meData.data.data.role)) {
    return <Redirect to="/" />;
  }
  return <Route {...props} />;
};

export const PrivateRoute = ({ meData, ...props }) => {
  const { getToken, removeToken } = useToken();
  const token = getToken();
  if (token && meData.error) {
    removeToken();
    return <Redirect to="/login" />;
  }
  if (!token || meData.error) {
    return <Redirect to="/login" />;
  }
  return <Route {...props} />;
};

// Every authenticated user can view OperatorRoutes
export const OperatorRoute = (props) => (
  <RoleRoute roles={[OPERATOR, BACKOFFICE, ADMIN]} {...props} />
);

// Only Backoffice and Admin users can view BackofficeRoutes
export const BackofficeRoute = (props) => (
  <RoleRoute roles={[BACKOFFICE, ADMIN]} {...props} />
);

// Only Admin users can view AdminRoutes
export const AdminRoute = (props) => <RoleRoute roles={[ADMIN]} {...props} />;
