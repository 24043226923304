export const transform = (cv, docCanvas, cropPoints, imageResizeRatio) => {
  const image = cv.imread(docCanvas);
  const { rb, lb, rt, lt } = cropPoints;

  // create source coordinates matrix
  const sourceCoordinates = [
    [lt.position.x / imageResizeRatio, lt.position.y / imageResizeRatio],
    [rt.position.x / imageResizeRatio, rt.position.y / imageResizeRatio],
    [rb.position.x / imageResizeRatio, rb.position.y / imageResizeRatio],
    [lb.position.x / imageResizeRatio, lb.position.y / imageResizeRatio],
  ];

  // get max width
  const maxWidth =
    Math.max(rb.position.x - lb.position.x, rt.position.x - lt.position.x) /
    imageResizeRatio;
  // get max height
  const maxHeight =
    Math.max(lb.position.y - lt.position.y, rb.position.y - rt.position.y) /
    imageResizeRatio;

  // create dest coordinates matrix
  const destCoordinates = [
    [0, 0],
    [maxWidth - 1, 0],
    [maxWidth - 1, maxHeight - 1],
    [0, maxHeight - 1],
  ];

  // convert to open cv matrix objects
  const sourceMatrix = cv.matFromArray(
    4,
    1,
    cv.CV_32FC2,
    [].concat(...sourceCoordinates)
  );
  const destinationMatrix = cv.matFromArray(
    4,
    1,
    cv.CV_32FC2,
    [].concat(...destCoordinates)
  );
  const transformMatrix = cv.getPerspectiveTransform(
    sourceMatrix,
    destinationMatrix
  );
  const newImageSize = new cv.Size(maxWidth, maxHeight);

  cv.warpPerspective(
    image,
    image,
    transformMatrix,
    newImageSize,
    cv.INTER_LINEAR,
    cv.BORDER_CONSTANT,
    new cv.Scalar()
  );
  cv.imshow(docCanvas, image);

  image.delete();
  sourceMatrix.delete();
  destinationMatrix.delete();
  transformMatrix.delete();
};

export const inverseTransform = (cv, inputImage, docCanvas, cropPoints) => {
  const outputImage = cv.imread(docCanvas);
  const { rb, lb, rt, lt } = cropPoints;

  // create dest coordinates matrix
  const sourceCoordinates = [
    [0, 0],
    [docCanvas.width, 0],
    [docCanvas.width - 1, docCanvas.height - 1],
    [0, docCanvas.height - 1],
  ];

  // create source coordinates matrix
  const destCoordinates = [
    [lt.position.x, lt.position.y],
    [rt.position.x, rt.position.y],
    [rb.position.x, rb.position.y],
    [lb.position.x, lb.position.y],
  ];

  // convert to open cv matrix objects
  const sourceMatrix = cv.matFromArray(
    4,
    1,
    cv.CV_32FC2,
    [].concat(...sourceCoordinates)
  );
  const destinationMatrix = cv.matFromArray(
    4,
    1,
    cv.CV_32FC2,
    [].concat(...destCoordinates)
  );
  const transformMatrix = cv.getPerspectiveTransform(
    sourceMatrix,
    destinationMatrix
  );
  const newImageSize = new cv.Size(docCanvas.width, docCanvas.height);

  cv.warpPerspective(
    inputImage,
    outputImage,
    transformMatrix,
    newImageSize,
    cv.INTER_LINEAR,
    cv.BORDER_CONSTANT,
    new cv.Scalar()
  );
  cv.imshow(docCanvas, outputImage);

  outputImage.delete();
  sourceMatrix.delete();
  destinationMatrix.delete();
  transformMatrix.delete();
};

export const drawGrid = (canvas) => {
  const ctx = canvas.getContext("2d");
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;
  const stepLenght = canvas.width / 20;

  let currentHorizontalPosition = 0;
  while (currentHorizontalPosition < canvasWidth) {
    ctx.save();
    ctx.strokeStyle = "#B1BECC";
    ctx.beginPath();
    ctx.moveTo(currentHorizontalPosition + stepLenght, 0);
    ctx.lineTo(currentHorizontalPosition + stepLenght, canvasHeight);
    ctx.stroke();
    ctx.restore();
    currentHorizontalPosition += stepLenght;
  }

  let currentVerticalPosition = 0;
  while (currentVerticalPosition < canvasHeight) {
    ctx.save();
    ctx.strokeStyle = "#B1BECC";
    ctx.beginPath();
    ctx.moveTo(0, currentVerticalPosition + stepLenght);
    ctx.lineTo(canvasWidth, currentVerticalPosition + stepLenght);
    ctx.stroke();
    ctx.restore();
    currentVerticalPosition += stepLenght;
  }
};

export const clearCanvas = (canvas) => {
  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.save();
  ctx.restore();
};
