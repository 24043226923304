import React, { useCallback } from "react";
import Draggable from "react-draggable";
import T from "prop-types";

const CropPoint = ({ size, bounds, position, setPosition }) => {
  const onDrag = useCallback((_, { x, y }) => setPosition(x + size / 2, y + size / 2), [setPosition]);
  return (
    <Draggable
      bounds={bounds}
      position={{ x: position.x - size / 2, y: position.y - size / 2 }}
      onDrag={onDrag}
    >
      <div
        style={{
          width: size,
          height: size,
          backgroundColor: "transparent",
          border: "4px solid #3cabe2",
          position: "absolute",
          zIndex: 1001,
        }}
      />
    </Draggable>
  );
};

export default CropPoint;

CropPoint.propTypes = {
  size: T.number,
  setPosition: T.func,
};
