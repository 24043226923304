import React from "react";

const Button = ({
  onClick,
  children,
  className,
  black,
  secondary,
  red,
  text,
  disabled,
  ...props
}) => {
  const baseClasses = "font-semibold px-4 py-3 transition-colors";
  const extraClassName = className ? className : "";
  // Default primary type button
  let variantClasses = "border-2 border-wf-blue bg-wf-blue text-white";
  if (secondary) {
    variantClasses = "border-2 border-gray-600";
  }
  if (text) {
    variantClasses = "";
  }
  if (black) {
    variantClasses = "bg-wf-800 text-white";
  }
  if (red) {
    variantClasses = "bg-wf-red text-white";
  }
  if (disabled) {
    variantClasses = "bg-gray-400 text-white";
  }

  return (
    <button
      {...props}
      disabled={disabled}
      className={`${baseClasses} ${variantClasses} ${extraClassName}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
