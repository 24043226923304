/*
 * Get the authentication token from the localStorage.
 */
export const getToken = () => {
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
};

export const humanize = (str) =>
  str
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, (m) => m.toUpperCase());

export const PART_NUMBER_STATUSES = {
  published: "PU",
  draft: "DR",
};

export function createFileFromBlob(blob, fileName) {
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  return blob;
}

export const USER_ROLES = {
  admin: "ADM",
  backOffice: "BCK",
  operator: "OPE",
};

export const getLocale = () => {
  return (
    navigator.userLanguage ||
    (navigator.languages &&
      navigator.languages.length &&
      navigator.languages[0]) ||
    navigator.language ||
    navigator.browserLanguage ||
    navigator.systemLanguage ||
    "en"
  );
};

export const PN_WIZARD_STEPS_COUNT = 5;

export const downloadFile = async (fileUrl, type) => {
  if (!fileUrl) {
    console.log("ERROR: fileUrl must be a valid url. Instead was: ", fileUrl);
    return;
  }
  const fileName = fileUrl.split("/").pop();
  const response = await fetch(fileUrl);
  const data = await response.blob();
  const metaData = {
    type: type,
  };
  return new File([data], fileName, metaData);
};
