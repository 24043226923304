import React from "react";

import { useTranslation } from "react-i18next";

import Label from "../Label";
import Button from "../Button";
import closeButtonIcon from "url:../../assets/close_icon.svg";

const SuccessModal = ({ closeAction }) => {
  const { t } = useTranslation();

  return (
    <div className="flex border-wf-blue border-t-4">
      <div className="flex flex-col whitespace-nowrap">
        <div className="flex flex-col container p-8">
          <Label className="flex font-noto font-bold text-2xl text-wf-900 mr-12.5">
            {t("partNumbers.wizard.successModal.title")}
          </Label>
          <Label className="flex font-noto text-base text-wf-900 mt-8">
            {t("partNumbers.wizard.successModal.body")}
          </Label>
        </div>
        <div className="flex justify-end bg-wf-100 h-20 px-8 py-4">
          <Button onClick={() => closeAction()}>
            {t("partNumbers.wizard.successModal.closeButton")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
