import React, { useState, useMemo, useEffect } from "react";
import ReactDOM from "react-dom";
import { useParams, Redirect, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../components/Breadcrumbs";
import Button from "../../components/Button";
import Container from "../../components/Container";
import DataTable from "../../components/DataTable";
import Label from "../../components/Label";
import Modal from "../../components/Modal";
import DesignFileUploadForm from "../../components/DesignFileUploadForm";
import GeneralInformationForm from "../../components/GeneralInformationForm";
import PcbBoardInfoForm from "../../components/PcbBoardInfoForm";
import ImageUploadForm from "../../components/ImageUploadForm";
import { PART_NUMBER_STATUSES, PN_WIZARD_STEPS_COUNT } from "../../utils";

import { useTogglable, useOnClickOutside } from "../../hooks";

import OverFlowIcon from "url:../../assets/overflow-menu.svg";
import EditIcon from "url:../../assets/edit.svg";

import {
  usePartNumberDetail,
  useWorkSessions,
  useUpdatePartNumber,
  usePartNumberDelete,
} from "../../hooks/queries";

const FileField = (props) => {
  return (
    <div className="flex flex-row justify-between bg-white p-3 border border-wf-300 flex-grow">
      <div className="flex flex-col flex-grow">
        <div className="text-wf-800 text-sm">{props.label}</div>
        <div className="text-wf-900 text-lg">{props.fileName}</div>
      </div>
      <button className="" onClick={props.action}>
        <img src={EditIcon} />
      </button>
    </div>
  );
};

const ImageComponent = (props) => {
  return (
    <div className={`flex flex-col border max-w-lg ${props.className}`}>
      <div className="flex flex-row bg-white w-full flex-grow">
        <div className="font-bold border flex-grow py-3 px-4 flex items-center">
          {props.label}
        </div>
        <div className="border py-3 px-4 flex items-center">
          {props.src.split("/").pop()}
          <button className="ml-4 w-5" onClick={props.action}>
            <img src={EditIcon} />
          </button>
        </div>
      </div>
      <div>
        <img src={props.src} />
      </div>
    </div>
  );
};

const WorkSessionsList = (props) => {
  const { t } = useTranslation();

  const columns = useMemo(() => [
    {
      Header: t("partNumbers.expandedView.serialNumber"),
      accessor: "serial_number",
      Cell: ({ value, row }) => {
        return (
          <Link
            to={`/part-numbers/${props.partNumberId}/work-sessions/${row.original.id}`}
            className="text-wf-blue font-bold"
          >
            {value}
          </Link>
        );
      },
    },
    {
      Header: t("partNumbers.expandedView.user"),
      accessor: "created_by",
    },
    {
      Header: t("partNumbers.expandedView.description"),
      accessor: "description",
    },
    {
      Header: t("partNumbers.expandedView.submitDate"),
      accessor: "created",
      Cell: ({ value }) => {
        if (value) {
          return (
            <>
              {new Intl.DateTimeFormat("default", {
                timeStyle: "medium",
                dateStyle: "short",
              }).format(new Date(value))}
            </>
          );
        }
        return <></>;
      },
    },
  ]);

  return (
    <>
      <Label className="bg-white border font-bold p-4">
        {t("partNumberDetail.workSessionsLabel")}
      </Label>
      <DataTable
        data={props.workSessions}
        columns={columns}
        pageCount={props.pageCount}
        setNextPage={props.setNextPage}
        setPreviousPage={props.setPreviousPage}
        setPage={props.setCurrentPage}
        data-test="latest-worksessions-table"
      />
    </>
  );
};

const ModalController = (props) => {
  switch (props.selectedPage) {
    case "design":
      return (
        <DesignFileUploadForm
          minimal
          partNumberID={props.partNumber.id}
          selectedPartNumber={props.partNumber}
          goToNextPage={props.modalClose}
          closeWizard={props.modalClose}
          wizardStep={2}
          wizardStepsCount={PN_WIZARD_STEPS_COUNT}
          setWizardStepProgress={() => null}
        />
      );
    case "bom":
      return (
        <PcbBoardInfoForm
          minimal
          selectedPartNumber={props.partNumber}
          partNumberID={props.partNumber.id}
          closeWizard={props.modalClose}
          onCreationComplete={console.log}
          wizardStep={5}
          wizardStepsCount={PN_WIZARD_STEPS_COUNT}
        />
      );
    case "topimage":
      return (
        <ImageUploadForm
          minimal
          partNumberID={props.partNumber.id}
          selectedPartNumber={props.partNumber}
          goToNextPage={props.modalClose}
          closeWizard={props.modalClose}
          topImage={true}
          wizardStep={3}
          wizardStepsCount={PN_WIZARD_STEPS_COUNT}
          setWizardStepProgress={console.log}
        />
      );
    case "bottomimage":
      return (
        <ImageUploadForm
          minimal
          partNumberID={props.partNumber.id}
          selectedPartNumber={props.partNumber}
          goToNextPage={props.modalClose}
          closeWizard={props.modalClose}
          topImage={false}
          wizardStep={4}
          wizardStepsCount={PN_WIZARD_STEPS_COUNT}
          setWizardStepProgress={console.log}
        />
      );
    case "info":
      return (
        <GeneralInformationForm
          minimal
          selectedPartNumber={props.partNumber}
          setPartNumberId={() => null}
          goToNextPage={props.modalClose}
          closeWizard={props.modalClose}
          wizardStep={1}
          wizardStepsCount={PN_WIZARD_STEPS_COUNT}
          setWizardStepProgress={console.log}
        />
      );
  }
};

const PartNumberDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const modal = useTogglable();
  const overflow = useTogglable();
  const { ref: popover } = useOnClickOutside(overflow.close);
  const [modalContent, setModalContent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const partNumberQuery = usePartNumberDetail(id, { retry: 1 });
  const partNumberMutation = useUpdatePartNumber(id);
  const partNumberDeleteAction = usePartNumberDelete(id);
  // Mutation to make the part number "published"
  const publish = () => {
    partNumberMutation
      .mutateAsync({ status: PART_NUMBER_STATUSES.published })
      .then(partNumberQuery.refetch);
  };
  // Mutation to make the part number "draft"
  const draft = () => {
    partNumberMutation
      .mutateAsync({ status: PART_NUMBER_STATUSES.draft })
      .then(partNumberQuery.refetch);
  };

  // Mutation to delete the part number
  const deletePartNumber = () => {
    partNumberDeleteAction
      .mutateAsync()
      .then(() => history.go("/part-numbers"));
  };

  const workSessionsQuery = useWorkSessions(
    {
      part_number: partNumberQuery.data?.data?.id,
      page: currentPage,
    },
    {
      enabled: partNumberQuery.data?.data?.id !== undefined,
    }
  );

  const partNumber = partNumberQuery.data?.data;
  const workSessions = workSessionsQuery.data?.data.results || [];
  const [pageCount, setPageCount] = useState(
    Math.ceil(workSessionsQuery.data?.data.count / 10)
  );
  useEffect(() => {
    setPageCount(Math.ceil(workSessionsQuery.data?.data.count / 10));
  }, [workSessionsQuery.data?.data.count]);

  if (partNumberQuery.isLoading) {
    return null;
  }

  if (partNumberQuery.isError) {
    return <Redirect to="/part-numbers" />;
  }

  const isPublished = partNumber.status === PART_NUMBER_STATUSES.published;

  // Change label depending on status
  const colors = isPublished
    ? "text-wf-green bg-wf-lightGreen"
    : "text-wf-800 bg-wf-200";
  const status = isPublished
    ? t("partNumbers.statuses.published")
    : t("partNumbers.statuses.draft");

  return (
    <>
      {ReactDOM.createPortal(
        <Modal isOpen={modal.isOpen} onClose={modal.close}>
          <ModalController
            selectedPage={modalContent}
            partNumber={partNumber}
            modalClose={() => {
              partNumberQuery.refetch();
              modal.close();
            }}
          />
        </Modal>,
        document.body
      )}
      <Breadcrumbs>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col">
            <div className="py-2 text-xl font-bold text-wf-900">
              {partNumber.name}
            </div>
            <div className="flex flex-row text-wf-900 text-sm items-center py-2">
              <div
                style={{
                  backgroundColor: partNumber.project?.color || "#FFFFFF",
                  borderRadius: 10,
                  width: 10,
                  height: 10,
                }}
                className="mr-1"
              />
              {partNumber.project?.name || t("partNumberDetail.noProject")}
            </div>
            <div className="pb-4 pt-2 text-wf-600">
              {partNumber.description}
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div
              className={`flex items-center px-4 h-10 mr-4 rounded-full w-min ${colors}`}
            >
              {status}
            </div>
            <Button
              disabled={
                partNumberMutation.isLoading || partNumberQuery.isLoading
              }
              onClick={isPublished ? draft : publish}
              secondary={isPublished}
            >
              {isPublished
                ? t("partNumberDetail.saveAsDraft")
                : t("partNumberDetail.publish")}
            </Button>
            <div className="relative">
              <Button text className="mx-4" onClick={overflow.toggle}>
                <img src={OverFlowIcon} />
              </Button>
              {overflow.isOpen && (
                <div
                  ref={popover}
                  className="absolute bg-white flex flex-col w-50 -left-32"
                  style={{
                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <button
                    className="p-4 border text-wf-800 font-semibold"
                    onClick={() => {
                      setModalContent("info");
                      modal.open();
                    }}
                  >
                    {t("partNumberDetail.editPartNumberInfo")}
                  </button>
                  <button
                    className="p-4 border text-wf-800 font-semibold"
                    onClick={deletePartNumber}
                  >
                    {t("partNumberDetail.deletePartNumber")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Breadcrumbs>
      <Container>
        <div className="flex flex-row w-full ">
          <FileField
            label="Design file"
            fileName={partNumber.ipc2581b.split("/").pop()}
            action={() => {
              setModalContent("design");
              modal.open();
            }}
          />
          <FileField
            label="BOM file"
            fileName={partNumber.bom?.split("/").pop() || "No file"}
            action={() => {
              setModalContent("bom");
              modal.open();
            }}
          />
        </div>
        <div className="flex flex-row items-start flex-wrap py-8">
          <ImageComponent
            src={partNumber.top_image}
            label={t("partNumberDetail.topImageLabel")}
            className="mr-4"
            action={() => {
              setModalContent("topimage");
              modal.open();
            }}
          />
          <ImageComponent
            src={partNumber.bottom_image}
            label={t("partNumberDetail.bottomImageLabel")}
            action={() => {
              setModalContent("bottomimage");
              modal.open();
            }}
          />
        </div>
        <div className="py-8">
          <WorkSessionsList
            partNumberId={partNumber.id}
            workSessions={workSessions}
            pageCount={pageCount}
            setNextPage={() => setCurrentPage(currentPage + 1)}
            setPreviousPage={() => setCurrentPage(currentPage - 1)}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </Container>
    </>
  );
};

export default PartNumberDetail;
