const deepCopy = (value) => JSON.parse(JSON.stringify(value));

const deg_to_rad = (deg) => (deg * Math.PI) / 180.0;

const rotate = (point, origin, angle) => {
  const ang = deg_to_rad(angle);
  const a = { x: Math.cos(ang), y: Math.sin(ang) };
  const v = { x: point[0] - origin[0], y: point[1] - origin[1] };
  return [origin[0] + a.x * v.x - a.y * v.y, origin[1] + a.y * v.x + a.x * v.y];
};

const getScaledCoordinates = (point, value) => [
  point[0] * value,
  point[1] * value,
];

const applyScale = (polygon, value) => {
  polygon.begin = getScaledCoordinates(polygon.begin, value);
  for (const step of polygon.steps) {
    if (step.type === "Segment") {
      step.end = getScaledCoordinates(step.end, value);
    } else if (step.type === "Curve") {
      step.origin = getScaledCoordinates(step.origin, value);
      step.end = getScaledCoordinates(step.end, value);
      step.radius *= value;
    }
  }
};

const translate = (point, location) => [
  point[0] + location[0],
  point[1] + location[1],
];

const applyTransform = (polygon, transform) => {
  if (transform.rotation && transform.rotation !== 0) {
    const new_origin = [transform.x_offset, transform.y_offset];
    polygon.begin = rotate(polygon.begin, new_origin, transform.rotation);
    for (const step of polygon.steps) {
      step.end = rotate(step.end, new_origin, transform.rotation);
      if (step.origin) {
        step.origin = rotate(step.origin, new_origin, transform.rotation);
      }
    }
  }

  if (transform.mirror) {
    polygon.begin[0] *= -1;
    for (const step of polygon.steps) {
      step.end[0] *= -1;
    }
  }

  if (transform.scale && transform.scale !== 1) {
    applyScale(polygon, transform.scale);
  }
};

const applyLocation = (polygon, location) => {
  polygon.begin = translate(polygon.begin, location);
  for (const step of polygon.steps) {
    if (step.type === "Segment") {
      step.end = translate(step.end, location);
    } else if (step.type === "Curve") {
      step.end = translate(step.end, location);
      step.origin = translate(step.origin, location);
    }
  }
};

export const makeComponent = (component, base_package) => {
  // Outline
  const outline = deepCopy(component.outline);
  applyTransform(outline, base_package.transform);
  applyLocation(outline, base_package.location);

  // Pins
  const pins = component.pins.map((p) => {
    const pin = deepCopy(p);
    applyTransform(pin, base_package.transform);
    applyLocation(pin, base_package.location);
    return pin;
  });

  // Assembly drawing
  let assembly_drawing = null;
  if (component.assembly_drawing) {
    const ad = deepCopy(component.assembly_drawing);
    applyTransform(ad, base_package.transform);
    applyLocation(ad, base_package.location);
    assembly_drawing = ad;
  }

  return {
    name: base_package.component_ref,
    visible: true,
    outline,
    pins,
    assembly_drawing,
  };
};
