import React from "react";

import Button from "../Button";
import Label from "../Label";

const EmptyState = ({
  image,
  label,
  buttonLabel,
  buttonAction = null,
  ...props
}) => {
  return (
    <div
      {...props}
      className="flex bg-wf-100 m-auto justify-items-center"
      data-test="empty-state-component"
    >
      <div className="flex flex-col m-auto mt-16 container">
        <img className="flex m-auto w-90 h-90 " src={image} />
        <Label className="flex m-auto mt-6 text-xl font-noto font-semibold">
          {label}
        </Label>
        {/* TODO: check why the input needs an empty string or undefined */}
        <Button onClick={() => buttonAction()} className="flex m-auto mt-6">
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};

export default EmptyState;
