import { isEmptyArray } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import Label from "../Label";
import LatestWorkSessionsEmptyState from "../LatestWorkSessionsEmptyState";
import MiniTable from "../MiniTable";
import { useData } from "./hooks";

const ExpandedRow = ({ rowData }) => {
  const { t } = useTranslation();
  const { data, columns } = useData(rowData.most_recent_work_sessions);
  let latestWorkSessions;

  if (
    rowData.most_recent_work_sessions === null ||
    rowData.most_recent_work_sessions === undefined ||
    rowData.most_recent_work_sessions.isEmptyArray
  ) {
    latestWorkSessions = <LatestWorkSessionsEmptyState />;
  } else {
    latestWorkSessions = (
      <div className="flex flex-col flex-grow ml-8">
        <Label className="font-noto text-wf-800 text-xs font-semibold mb-2">
          {t("partNumbers.expandedView.miniTableTitle")}
        </Label>
        <MiniTable
          data={data}
          columns={columns}
          data-test="latest-worksessions-table"
        />
      </div>
    );
  }

  return (
    <div className="flex flex-row mt-6 mb-8 border-wf-200 h-52">
      <div className="flex flex-col w-50 h-38">
        {rowData.top_image && (
          <Label className="font-noto text-wf-800 text-xs font-semibold mb-2">
            {t("partNumbers.expandedView.topImage")}
          </Label>
        )}
        <img src={rowData.top_image} />
      </div>
      <div className="flex flex-col w-50 h-38 ml-6">
        {rowData.bottom_image && (
          <Label className="font-noto text-wf-800 text-xs font-semibold mb-2">
            {t("partNumbers.expandedView.bottomImage")}
          </Label>
        )}
        <img className="flex object-contain" src={rowData.bottom_image} />
      </div>
      <>{latestWorkSessions}</>
    </div>
  );
};

export default ExpandedRow;
