import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import App from "./app/App";
import { AxiosProvider } from "./app/utils/client";

import "./i18n";

const client = new QueryClient();

ReactDOM.render(
  <AxiosProvider>
    <QueryClientProvider client={client}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </QueryClientProvider>
  </AxiosProvider>,
  document.getElementById("root")
);
