import React from "react";
import { useTranslation } from "react-i18next";
import logo from "url:../../assets/cbl_logo.png";
import Label from "../Label/index";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="w-full flex items-center align-middle border-t-2 border-wf-300 bg-white justify-between h-16 px-34">
      <img className="object-contain h-8" src={logo} />
      <Label size="small">{t("footer.copyrightInfo")}</Label>
    </footer>
  );
};

export default Footer;
