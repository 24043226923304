import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useOpenCv } from "opencv-react";
import T from "prop-types";

import CropPoint from "./CropPoint";
import CropPointsDelimiters from "./CropPointsDelimiters";
import { calculateDimensions, readFile } from "./utils";
import {
  transform,
  inverseTransform,
  drawGrid,
  clearCanvas,
} from "./imgManipulation";
import { drawSchematics, drawPolygon, makeDrawData } from "./drawSchematics";
import { getSize } from "../../utils/drawing";

const useCropPoint = (size, callback) => {
  const [position, _setPosition] = useState({ x: 0, y: 0 });
  const setPosition = (x, y) => {
    _setPosition({ x, y });
    if (callback) callback();
  };
  const hit = (x, y) =>
    x > position.x - size * 2 &&
    x < position.x + size * 2 &&
    y > position.y - size * 2 &&
    y < position.y + size * 2;

  return { position, setPosition, hit };
};

const useCropPoints = (size, callback) => {
  const rb = useCropPoint(size, callback);
  const rt = useCropPoint(size, callback);
  const lb = useCropPoint(size, callback);
  const lt = useCropPoint(size, callback);
  const hit = (x, y) =>
    rb.hit(x, y) || rt.hit(x, y) || lb.hit(x, y) || lt.hit(x, y);

  return {
    rb,
    rt,
    lb,
    lt,
    hit,
  };
};

const Canvas = ({
  className,
  cropperRef,
  drawData,
  gridEnabled,
  image,
  layerName,
  maxHeight,
  maxWidth,
  mirrorX,
  mirrorY,
  onImageCropped,
  pointSize,
  profileEnabled,
  setTouched,
  zoom,
}) => {
  const { loaded: cvLoaded, cv } = useOpenCv();

  // Canvases
  const canvasRef = useRef();
  const previewCanvasRef = useRef();
  const schematicsCanvasRef = useRef();

  // Dimension
  const [previewDimentions, setPreviewDimensions] = useState();
  const [imageResizeRatio, setImageResizeRatio] = useState(1);

  // Images
  const [originalImage, setOriginalImage] = useState();
  const [schematicsImage, setSchematicsImage] = useState();

  // Crop points
  const cropPoints = useCropPoints(pointSize, () => setTouched(true));

  // Draw data info
  const { width, height, minx, miny } = getSize(drawData.profile.polygon);

  useImperativeHandle(cropperRef, () => ({
    crop: async () => {
      return new Promise((resolve) => {
        transform(cv, canvasRef.current, cropPoints, imageResizeRatio);
        // Now put the image inside an Image object, and when it's loaded
        // clip the image following pcb's profile
        const imageData = new Image();
        imageData.src = canvasRef.current.toDataURL();
        imageData.onload = () => {
          const ctx = canvasRef.current.getContext("2d");
          clearCanvas(canvasRef.current);

          // Save ctx so we can reset the transform later
          ctx.save();
          // Mirror axes
          ctx.translate(
            mirrorX ? canvasRef.current.width : 0,
            mirrorY ? canvasRef.current.height : 0
          );
          ctx.scale(mirrorX ? -1 : 1, mirrorY ? -1 : 1);
          // Center the PCB
          const widthScale = canvasRef.current.width / width;
          const heightScale = canvasRef.current.height / height;
          ctx.translate(-minx * widthScale, -miny * heightScale);
          // Draw the profile and use it as clip path
          const { profile } = makeDrawData(drawData);
          drawPolygon(ctx, profile, widthScale, heightScale, false, false);
          ctx.clip();
          // Restore the ctx transform
          ctx.restore();

          // Draw the image inside the clip path
          ctx.drawImage(imageData, 0, 0);
          // Reset translate, call onImageCropped and resolve
          setTranslate({ x: 0, y: 0 });
          onImageCropped();
          canvasRef.current.toBlob((blob) => {
            blob.name = image.name;
            resolve(blob);
          }, image.type);
        };
      });
    },
    rotate: (angle) => {
      const image = new Image();
      image.onload = async () => {
        const ctx = canvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        ctx.save();
        ctx.translate(
          canvasRef.current.width / 2,
          canvasRef.current.height / 2
        );
        ctx.rotate((angle * Math.PI) / 180);
        ctx.drawImage(image, -image.width / 2, -image.height / 2);
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.restore();
        showPreview();
      };
      image.src = originalImage.src;
    },
  }));

  useEffect(() => {
    if (previewDimentions?.width && previewDimentions?.height) {
      previewCanvasRef.current.width = previewDimentions.width;
      previewCanvasRef.current.height = previewDimentions.height;
      schematicsCanvasRef.current.width = previewDimentions.width;
      schematicsCanvasRef.current.height = previewDimentions.height;
    }
  }, [previewDimentions?.width, previewDimentions?.height]);

  const createCanvas = (src) => {
    return new Promise((resolve) => {
      const img = document.createElement("img");
      img.onload = async () => {
        // set edited image canvas and dimensions
        setOriginalImage(img);
        canvasRef.current = document.createElement("canvas");
        canvasRef.current.width = img.width;
        canvasRef.current.height = img.height;

        // set preview pane dimensions
        const { width, height } = calculateDimensions(
          canvasRef.current.width,
          canvasRef.current.height,
          maxWidth,
          maxHeight
        );
        previewCanvasRef.current.width = width;
        previewCanvasRef.current.height = height;
        schematicsCanvasRef.current.width = width;
        schematicsCanvasRef.current.height = height;
        setPreviewDimensions({ width, height });
        setImageResizeRatio(width / canvasRef.current.width);
        const ctx = canvasRef.current.getContext("2d");
        ctx.drawImage(img, 0, 0);
        resolve();
      };
      img.src = src;
    });
  };

  const showPreview = (
    wRatio = imageResizeRatio,
    hRatio = imageResizeRatio
  ) => {
    const src = cv.imread(canvasRef.current);
    const dsize = new cv.Size(0, 0);

    cv.resize(src, src, dsize, wRatio, hRatio, cv.INTER_AREA);

    cv.imshow(previewCanvasRef.current, src);
    src.delete();
  };

  useEffect(() => {
    if (cvLoaded && schematicsImage && schematicsCanvasRef.current) {
      inverseTransform(
        cv,
        schematicsImage,
        schematicsCanvasRef.current,
        cropPoints
      );
    }
  }, [cropPoints]);

  useEffect(() => {
    const bootstrap = async () => {
      const src = await readFile(image);
      await createCanvas(src);
      // Set points to initial position
      const { width, height } = calculateDimensions(
        canvasRef.current.width,
        canvasRef.current.height,
        maxWidth,
        maxHeight
      );
      showPreview(
        width / canvasRef.current.width,
        height / canvasRef.current.height
      );
      cropPoints.lt.setPosition(0, 0);
      cropPoints.rt.setPosition(width, 0);
      cropPoints.rb.setPosition(width, height);
      cropPoints.lb.setPosition(0, height);
      setTouched(false);
    };

    if (image && previewCanvasRef.current && cvLoaded) {
      bootstrap();
    }
  }, [image, previewCanvasRef.current, cvLoaded]);

  useEffect(() => {
    if (image && schematicsCanvasRef.current && cvLoaded) {
      clearCanvas(schematicsCanvasRef.current);
      if (gridEnabled) {
        drawGrid(schematicsCanvasRef.current);
      }
      if (profileEnabled) {
        const widthScale = schematicsCanvasRef.current.width / width;
        const heightScale = schematicsCanvasRef.current.height / height;
        drawSchematics(
          schematicsCanvasRef,
          drawData,
          layerName,
          widthScale,
          heightScale,
          mirrorX,
          mirrorY,
          minx,
          miny
        );
      }
      setSchematicsImage(cv.imread(schematicsCanvasRef.current));
    }
  }, [
    image,
    cvLoaded,
    schematicsCanvasRef.current?.width,
    layerName,
    gridEnabled,
    profileEnabled,
    mirrorX,
    mirrorY,
  ]);

  const [isDragging, setIsDragging] = useState(false);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  const bounds = {
    left: previewCanvasRef.current?.offsetLeft - pointSize * 2,
    top: previewCanvasRef.current?.offsetTop - pointSize * 2,
    right:
      previewCanvasRef.current?.offsetLeft +
      pointSize +
      previewCanvasRef.current?.offsetWidth,
    bottom:
      previewCanvasRef.current?.offsetTop +
      pointSize +
      previewCanvasRef.current?.offsetHeight,
  };

  return (
    <div
      className={`relative ${className}`}
      onPointerDown={({ clientX, clientY }) => {
        const { x: dx, y: dy } =
          schematicsCanvasRef.current.getBoundingClientRect();
        const x = clientX - dx;
        const y = clientY - dy;
        if (!isDragging && !cropPoints.hit(x, y)) {
          setIsDragging(true);
        }
      }}
      onPointerUp={() => setIsDragging(false)}
      onPointerOut={() => setIsDragging(false)}
      onPointerMove={({ movementX: dx, movementY: dy }) => {
        if (isDragging) {
          setTranslate(({ x, y }) => ({ x: x + dx, y: y + dy }));
        }
      }}
      style={{
        width: previewDimentions?.width,
        height: previewDimentions?.height,
        transform: `scale(${zoom}) translateX(${translate.x}px) translateY(${translate.y}px)`,
      }}
    >
      {previewDimentions && cropPoints && (
        <>
          <CropPoint size={pointSize} bounds={bounds} {...cropPoints.lt} />
          <CropPoint size={pointSize} bounds={bounds} {...cropPoints.rt} />
          <CropPoint size={pointSize} bounds={bounds} {...cropPoints.rb} />
          <CropPoint size={pointSize} bounds={bounds} {...cropPoints.lb} />
          <CropPointsDelimiters
            width={previewDimentions.width}
            height={previewDimentions.height}
            cropPoints={cropPoints}
            pointSize={pointSize}
          />
        </>
      )}
      <div className="relative">
        <canvas
          id="previewCanvas"
          className="absolute pointer-events-none z-10 "
          ref={previewCanvasRef}
        />
        <canvas
          id="schematicsCanvas"
          className="absolute z-20 pointer-events-none bg-transparent"
          ref={schematicsCanvasRef}
        />
      </div>
    </div>
  );
};

export default Canvas;

Canvas.propTypes = {
  image: T.object.isRequired,
  cropperRef: T.shape({
    current: T.shape({
      crop: T.func.isRequired,
    }),
  }),
  pointSize: T.number,
};
