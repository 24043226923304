import React from "react";

import { useTranslation } from "react-i18next";

import workSessionsEmptyState from "url:../../assets/worksessions_empty_state.svg";
import Label from "../Label";

const LatestWorkSessionsEmptyState = () => {
  const { t } = useTranslation();

  return (
    <div className="inline-flex items-center justify-end flex-grow pl-6">
      <img className="w-32 h-32 mr-6" src={workSessionsEmptyState} />
      <Label className="text-sm text-wf-700">
        {t("partNumbers.expandedView.emptyWorkSessions")}
      </Label>
    </div>
  );
};

export default LatestWorkSessionsEmptyState;
