import React from "react";
import { NavLink } from "react-router-dom";

export const UserNavLink = ({ to, children, ...props }) => {
  return (
    <NavLink
      to={to}
      exact
      activeClassName="header-link font-noto font-semibold text-sm text-blue-500 py-3"
      className="font-noto font-semibold text-sm py-3 mr-4"
      {...props}
    >
      {children}
    </NavLink>
  );
};
