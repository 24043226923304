import React, { useState } from "react";
import ReactDOM from "react-dom";

import { useTranslation } from "react-i18next";
import { useTogglable, useOnClickOutside } from "../../hooks";

import chevronDown from "url:../../assets/chevron_down.svg";
import chevronUp from "url:../../assets/chevron_up.svg";
import { ProjectForm } from "../../pages/ProjectsList";
import Modal from "../Modal";

const ProjectDropdownSelector = ({
  selectedProject,
  projects,
  setSearchQuery,
  onSelect,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [searching, setSearching] = useState(false);
  const createProjectOption = useTogglable();
  const createProjectModal = useTogglable(false);
  const dropDownMenu = useTogglable();
  const { ref } = useOnClickOutside(dropDownMenu.close);

  const handleSelect = (project) => {
    onSelect(project);
    createProjectOption.close();
    createProjectModal.close();
    dropDownMenu.close();
    setSearching(false);
  };

  const handleOnChange = (e) => {
    setInputValue(e.target.value);
    onSelect(null);
    setSearching(true);
    setSearchQuery(e.target.value);

    const trimmedInput = e.target.value.trim();
    if (trimmedInput === undefined || trimmedInput === "") {
      createProjectOption.close();
      return;
    }

    dropDownMenu.open();
    createProjectOption.open();
  };

  const handleCreateProjectModalCancelAction = () => {
    setInputValue("");
    setSearchQuery("");
    createProjectOption.close();
    createProjectModal.close();
  };

  return (
    <div ref={ref} className="relative inline-block">
      <p className="text-sm text-wf-800 font-semibold mb-1.5">
        {t("partNumbers.wizard.generalInformationForm.project")}
      </p>
      <div
        className="inline-flex items-center justify-between bg-wf-input border-b border-wf-500 text-wf-900 w-full p-3"
        onClick={dropDownMenu.toggle}
      >
        <div className="inline-flex items-center w-full">
          <div
            style={{
              backgroundColor: selectedProject
                ? selectedProject.color
                : "#1192E8",
            }}
            className={"rounded-full w-2 h-2 mr-3 shadow"}
          />
          <input
            className="placeholder-gray-600 w-full bg-transparent outline-none"
            type="text"
            placeholder={t(
              "partNumbers.wizard.generalInformationForm.projectHint"
            )}
            value={searching ? inputValue : selectedProject?.name}
            onChange={handleOnChange}
          />
        </div>
        <img src={dropDownMenu.isOpen ? chevronUp : chevronDown} />
      </div>
      {dropDownMenu.isOpen && (
        <div className="absolute right-0 w-full mt-0 border-2 border-wf-blue">
          {projects.map((project, index) => (
            <div
              key={project.id}
              className="bg-wf-100 p-3 text-wf-900 border-b border-wf-500"
              onClick={() => handleSelect(project)}
            >
              <div className="inline-flex items-center">
                <div
                  style={{ backgroundColor: project.color }}
                  className="rounded-full w-2 h-2 mr-3 shadow"
                />
                {project.name}
              </div>
            </div>
          ))}
          {createProjectOption.isOpen && (
            <div
              className="bg-wf-100 p-3 text-wf-900 border-b border-wf-500"
              onClick={createProjectModal.open}
            >
              <div className="flex place-content-center">
                {t("partNumbers.wizard.generalInformationForm.createProject")}
              </div>
            </div>
          )}
        </div>
      )}
      {ReactDOM.createPortal(
        <Modal innerRef={ref} isOpen={createProjectModal.isOpen}>
          <ProjectForm
            className="inline-flex bg-white shadow-2xl transform transition-all"
            initialValue={inputValue}
            closeModal={handleCreateProjectModalCancelAction}
            handleProjectCreated={handleSelect}
          />
        </Modal>,
        document.body
      )}
      <div className="origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"></div>
    </div>
  );
};

export default ProjectDropdownSelector;
