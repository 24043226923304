import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import Button from "../Button";
import Label from "../Label";
import ImageCropper from "../ImageCropper";
import ImageFileSelector from "../ImageFileSelector";
import NonFieldError from "../NonFieldError";
import {
  useWizardUpdatePartNumber,
  usePartNumberDetail,
} from "../../hooks/queries";
import { downloadFile } from "../../utils";

const ImageUploadForm = ({
  partNumberID,
  selectedPartNumber,
  goToNextPage,
  closeWizard,
  wizardStep,
  wizardStepsCount,
  setWizardStepProgress,
  topImage = false,
  minimal,
}) => {
  const { t } = useTranslation();
  const imageFieldName = topImage ? "top_image" : "bottom_image";
  const [fileToUpload, _setFileToUpload] = useState(null);
  const [fileError, setFileError] = useState(null);
  const updatePartNumber = useWizardUpdatePartNumber(partNumberID);
  const partNumberDetail = usePartNumberDetail(partNumberID);
  const [drawData, setdrawData] = useState(null);
  const [touched, setTouched] = useState(false);

  const setFileToUpload = (file) => {
    _setFileToUpload(file);
    setFileError(null);
  };

  useEffect(() => {
    if (partNumberDetail.isSuccess) {
      setdrawData(partNumberDetail.data.data.draw_data);
    }
  }, [partNumberDetail]);

  const handleSubmit = async (onUploadCompleted) => {
    const formData = new FormData();
    formData.append(imageFieldName, fileToUpload);
    formData.append("wizard_step", wizardStep);
    await updatePartNumber
      .mutateAsync(formData)
      .then(() => {
        onUploadCompleted();
        setWizardStepProgress(wizardStep + 1);
      })
      .catch((error) => {
        const data = error.response?.data;
        setFileError(data[imageFieldName]);
      });
  };

  useEffect(() => {
    setFileToUpload(null);
    if (selectedPartNumber) {
      const imageUrl = topImage
        ? selectedPartNumber.top_image
        : selectedPartNumber.bottom_image;
      if (imageUrl) {
        downloadFile(
          topImage
            ? selectedPartNumber.top_image
            : selectedPartNumber.bottom_image,
          "image/*"
        )
          .then((file) => {
            setFileToUpload(file);
          })
          .catch((error) => {
            console.log(error);
          });
        return;
      }
    }
  }, [topImage, selectedPartNumber]);

  const imageSelectionArea =
    fileToUpload && drawData ? (
      <ImageCropper
        image={fileToUpload}
        onImageCropped={setFileToUpload}
        reset={() => setFileToUpload(null)}
        partNumberID={selectedPartNumber ? selectedPartNumber.id : partNumberID}
        isTopLayer={topImage}
        drawData={drawData}
        setTouched={setTouched}
      />
    ) : (
      <ImageFileSelector topImage={topImage} onFileSelected={setFileToUpload} />
    );

  return (
    <div className="flex w-full border border-wf-300 mx-34">
      <div className="flex w-full flex-col bg-wf-input">
        {!minimal && (
          <div className="flex border-b border-wf-300 bg-white px-8 py-4">
            <Label className="text-base text-wf-900 font-noto font-semibold">
              {t(`partNumbers.wizard.imageUploadForm.formTitle`, {
                currentStep: wizardStep,
                wizardStepsCount: wizardStepsCount,
              })}
            </Label>
          </div>
        )}
        {imageSelectionArea}
        {fileError && <NonFieldError errorMessage={fileError} />}
        <div className="flex justify-end bg-wf-input border-t border-wf-300 h-20 px-8 py-4">
          {!minimal && (
            <button
              className="border-wf-900 border-2 px-3 mr-2"
              onClick={() => handleSubmit(closeWizard)}
              disabled={touched}
            >
              {t("partNumbers.wizard.generalInformationForm.saveAsDraftButton")}
            </button>
          )}
          <Button onClick={() => handleSubmit(goToNextPage)} disabled={touched}>
            {t(
              "partNumbers.wizard.generalInformationForm.saveAndProceedButton"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageUploadForm;
