import Axios from "axios";
import React, { createContext, useMemo } from "react";

export const AxiosContext = createContext(undefined);

export const AxiosProvider = ({ children }) => {
  const axios = useMemo(() => {
    const axios = Axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });

    axios.interceptors.request.use((config) => {
      // Read token for anywhere, in this case directly from localStorage
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Token ${JSON.parse(token).token}`;
      }
      return config;
    });
    return axios;
  }, []);

  return (
    <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
  );
};
