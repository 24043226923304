import { makeComponent } from "./drawComponent";

export const contains = (x, y, cx, cy, hs) =>
  x > cx - hs && x < cx + hs && y > cy - hs && y < cy + hs;

export const getScaleForDimensions = (
  schematicsWidth,
  schematicsHeight,
  canvasWidth,
  canvasHeight
) =>
  schematicsWidth > schematicsHeight
    ? canvasHeight / schematicsHeight
    : canvasWidth / schematicsWidth;

export const makeDrawData = (drawData) => {
  const profile = drawData.profile.polygon;
  return {
    profile,
    layers: Object.values(drawData.layers).map((layer) => ({
      name: layer.name,
      visible: true,
      nets: Object.values(layer.nets),
      components: layer.components.map((base_package) =>
        makeComponent(
          drawData.components[base_package.component_ref],
          base_package
        )
      ),
    })),
  };
};

const angleBetween = (origin, start, end, clockwise) => {
  const vec1 = [end[0] - origin[0], end[1] - origin[1]];
  const vec2 = [start[0] - origin[0], start[1] - origin[1]];
  const ang1 = Math.atan2(vec1[1], vec1[0]);
  const ang2 = Math.atan2(vec2[1], vec2[0]);
  let res = (ang1 - ang2) % (2 * Math.PI);
  res = clockwise ? -res : res;
  if (res < 0) res += 2 * Math.PI;
  return res;
};

export const drawPolygon = (
  ctx,
  polygon,
  widthScale,
  heightScale,
  close = true,
  stroke = true
) => {
  let lastPoint = [
    polygon.begin[0] * widthScale,
    polygon.begin[1] * heightScale,
  ];
  ctx.beginPath();
  ctx.moveTo(lastPoint[0], lastPoint[1]);
  for (const step of polygon.steps) {
    if (step.type === "Segment") {
      ctx.lineTo(step.end[0] * widthScale, step.end[1] * heightScale);
    } else {
      const startAngle = angleBetween(
        [step.origin[0] * widthScale, step.origin[1] * heightScale],
        [
          step.origin[0] * widthScale + step.radius * widthScale,
          step.origin[1] * heightScale,
        ],
        lastPoint,
        step.clockwise
      );
      const endAngle = angleBetween(
        [step.origin[0] * widthScale, step.origin[1] * heightScale],
        [
          step.origin[0] * widthScale + step.radius * widthScale,
          step.origin[1] * heightScale,
        ],
        [step.end[0] * widthScale, step.end[1] * heightScale],
        step.clockwise
      );
      ctx.ellipse(
        step.origin[0] * widthScale,
        step.origin[1] * heightScale,
        step.radius * widthScale,
        step.radius * heightScale,
        0,
        startAngle,
        endAngle,
        step.clockwise
      );
    }
    lastPoint = [step.end[0] * widthScale, step.end[1] * heightScale];
  }
  if (close) ctx.closePath();
  if (stroke) ctx.stroke();
};

export const drawSchematics = (
  canvasRef,
  drawData,
  layerName,
  widthScale,
  heightScale,
  mirrorX,
  mirrorY,
  minx,
  miny
) => {
  const ctx = canvasRef.current.getContext("2d");
  ctx.save();
  ctx.fillStyle = "#F040F040";
  ctx.strokeStyle = "#FFFF00";

  // Center the PCB in case it's borders are not on the 0
  ctx.translate(-minx * widthScale, -miny * heightScale);
  // Mirror axes on request
  ctx.translate(
    mirrorX ? canvasRef.current.width : 0,
    mirrorY ? canvasRef.current.height : 0
  );
  ctx.scale(mirrorX ? -1 : 1, mirrorY ? -1 : 1);

  const schematics = makeDrawData(drawData);
  drawPolygon(ctx, schematics.profile, widthScale, heightScale);
  for (const layer of schematics.layers) {
    if (layer.name === layerName) {
      // Avoid drawing nets here since we probably won't need them
      // for (const net of layer.nets) {
      //   for (const polygon of net.polygons) {
      //     drawPolygon(ctx, polygon, widthScale, heightScale, false);
      //   }
      // }
      for (const component of layer.components) {
        // drawPolygon(ctx, component.outline, widthScale, heightScale);
        if (component.assembly_drawing) {
          drawPolygon(ctx, component.assembly_drawing, widthScale, heightScale);
        }
        for (const pin of component.pins) {
          drawPolygon(ctx, pin, widthScale, heightScale);
        }
      }
    }
  }
  ctx.restore();
};
