import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

/*
 * Radio option input with included label, used in forms.
 * @param {String} name - Field name
 * @param {String} label - Field label
 * @param {String} value - Radio option value
 */
const RadioLabel = ({ name, label, value }) => {
  return (
    <>
      <Field
        className="text-blue-600 text-opacity-100 mr-2"
        type="radio"
        name={name}
        value={value}
      />
      <label className="pr-8" htmlFor={name}>
        {label}
      </label>
    </>
  );
};

RadioLabel.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default RadioLabel;
