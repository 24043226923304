import React from "react";
import { useTranslation } from "react-i18next";

import Container from "../Container";

const Error = ({ error }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <p>{t("error.message")}</p> <sub>{error.message}</sub>
    </Container>
  );
};

export default Error;
