import React from "react";

import { ErrorMessage } from "formik";

const InputError = (props) => {
  return (
    <ErrorMessage {...props}>
      {(msg) => <div className="text-red-500 p-2 bg-red-50 my-2">{msg}</div>}
    </ErrorMessage>
  );
};

export default InputError;
