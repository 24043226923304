import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import TextInput from "../../components/Input/TextInput";
import InputError from "../../components/InputError";
import Label from "../../components/Label";
import Button from "../../components/Button";
import logo from "url:../../assets/cbl_logo.png";
import loginBackground from "url:../../assets/login_background.png";
import { useSendPasswordResetLink } from "../../hooks/queries";

const schema = Yup.object({
  email: Yup.string().required("Email is required"),
});

const ForgotPassword = () => {
  const { t } = useTranslation();
  const sendPasswordResetLink = useSendPasswordResetLink();
  const [actionCompleted, setActionCompleted] = useState(false);

  let pageContent = (
    <>
      <h1 className="font-bold text-xl mb-2">{t("forgotPassword.title")}</h1>
      <Label className="text-wf-900 text-base font-noto mb-4">
        {t("forgotPassword.description")}
      </Label>
      <div className="container flex flex-col space-y-1 mb-4">
        <TextInput
          label={t("forgotPassword.email")}
          name="email"
          backgroundColor="white"
        />
        <InputError name="email" />
      </div>
      <div className="flex justify-end mt-4">
        <Button type="submit" id="confirmAccount">
          {t("forgotPassword.resetButton")}
        </Button>
      </div>
    </>
  );

  if (actionCompleted) {
    pageContent = (
      <>
        <Label className="text-wf-900 text-base font-noto font-semibold mb-4">
          {t("forgotPassword.successMessage")}
        </Label>
        <Label className="text-wf-blue text-base underline cursor-pointer font-noto mb-4">
          <Link to="/login">{t("forgotPassword.goToLogin")}</Link>
        </Label>
      </>
    );
  }

  return (
    <div className="flex flex-row flex-grow bg-wf-100">
      <div
        className="bg-black max-h-screen bg-opacity-50 bg-cover w-login"
        style={{
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45) ), url("${loginBackground}")`,
        }}
      />
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={async (values, actions) => {
          await sendPasswordResetLink
            .mutateAsync(values)
            .then(() => {
              setActionCompleted(true);
            })
            .catch((error) => {
              actions.setErrors(error.response.data);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={schema}
        data-test="forgot-password-form"
      >
        <Form className="container w-90 m-auto" data-test="login-component">
          <div className="flex flex-col bg-transparent">
            <img
              className="justify-start object-contain h-8 w-22 mb-8"
              src={logo}
            />
            {pageContent}
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ForgotPassword;
