import React, { useState } from "react";

import Button from "../Button";

import Chevron from "url:../../assets/chevron.svg";
import Back from "url:../../assets/back.svg";
import MenuIcon from "url:../../assets/overflow-menu.svg";

import { useOnClickOutside } from "../../hooks";
import { useTranslation } from "react-i18next";

const MarkersList = ({ markers, controls }) => {
  const { t } = useTranslation();
  const { ref } = useOnClickOutside(controls.menu.close);
  return (
    <div className="bg-white border-wf-300 py-1 px-2 flex flex-col w-90">
      {controls.detail.isOpen ? (
        <div className="flex flex-col py-2">
          <div className="flex flex-row justify-between">
            <Button text onClick={controls.detail.close}>
              <img src={Back} className="w-5 h-5" />
            </Button>
            <div ref={ref} className="relative">
              <Button
                text
                onClick={controls.menu.toggle}
                className={controls.menu.isOpen ? "bg-wf-300" : ""}
              >
                <img src={MenuIcon} className="w-5 h-5" />
              </Button>
              {controls.menu.isOpen && (
                <div
                  className="absolute bg-white -left-24"
                  style={{ boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.15)" }}
                >
                  <Button text>{t("workSessionDetail.editMarker")}</Button>
                  <div className="border border-wf-100" />
                  <Button text>{t("workSessionDetail.deleteMarker")}</Button>
                </div>
              )}
            </div>
          </div>
          <div className="p-4">
            <div className="font-bold text-wf-900 text-xl py-2">
              {controls.detail.marker.name}
            </div>
            <div className="text-wf-800 text-sm py-2">
              {t("workSessionDetail.modifiedAt")}
              {" - "}
              {new Date(controls.detail.marker.modified).toLocaleString()}
            </div>
            <div className="border border-wf-100" />
            <div className="text-wf-800 text-sm py-2">
              {t("workSessionDetail.screenshot")}
            </div>
            <div className="border border-wf-100" />
            <div className="text-wf-800 text-sm py-2">
              {controls.detail.marker.description}
            </div>
          </div>
        </div>
      ) : (
        <div className="p-4">
          {markers.map((m) => (
            <div className="flex flex-col py-2" key={`${m.id}-${m.name}`}>
              <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                  <div className="font-bold text-wf-900 text-xl py-1">
                    {m.name}
                  </div>
                  <div className="text-wf-800 text-sm py-1">
                    {m.description}
                  </div>
                </div>
                <Button text onClick={() => controls.detail.open(m)}>
                  <img src={Chevron} className="w-5 h-5" />
                </Button>
              </div>
              <div className="border-b-2 border-wf-300 py-1" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MarkersList;
