import React, { useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import TextInput from "../../components/Input/TextInput";
import InputError from "../../components/InputError";
import NonFieldError from "../../components/NonFieldError";
import { useToken } from "../../hooks";
import { useLogin } from "../../hooks/queries";
import Button from "../../components/Button";
import { useQueryClient } from "react-query";
import logo from "url:../../assets/cbl_logo.png";
import loginBackground from "url:../../assets/login_background.png";

const schema = Yup.object({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

/*
 * Login page.
 * Since meData has been fetched already, we don't
 * send a new request unless needed.
 */
const Login = ({ loggedIn }) => {
  const { t } = useTranslation();
  const { setToken } = useToken();
  const login = useLogin();
  const clientQuery = useQueryClient();
  const [loginError, setLoginError] = useState();
  // If the token exists, redirect to the HomePage
  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className="flex flex-row flex-grow bg-wf-100">
      <div
        className="bg-black max-h-screen bg-opacity-50 bg-cover w-login"
        style={{
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45) ), url("${loginBackground}")`,
        }}
      />
      <Formik
        initialValues={{ username: "", password: "" }}
        onSubmit={async (values, actions) => {
          await login
            .mutateAsync(values)
            .then((response) => {
              setToken(response.data);
              setLoginError(null);
              clientQuery.invalidateQueries("meData");
            })
            .catch((error) => {
              actions.setErrors(error.response.data);
              error.response.data.non_field_errors
                ? setLoginError(error.response.data.non_field_errors)
                : setLoginError(null);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={schema}
        data-test="login-form"
      >
        <Form className="container w-90 m-auto" data-test="login-component">
          <div className="flex flex-col bg-transparent">
            <img
              className="justify-start object-contain h-8 w-22 mb-8"
              src={logo}
            />
            <h1 className="font-bold text-xl mb-8">{t("login.signinText")}</h1>
            <div className="flex flex-col mb-4">
              <TextInput
                name="username"
                label={t("login.usernamePlaceholder")}
                backgroundColor="white"
              />
              <InputError name="username" />
            </div>
            <div className="flex flex-col">
              <TextInput
                name="password"
                label={t("login.passwordPlaceholder")}
                backgroundColor="white"
                password
              />
              <InputError name="password" />
              {loginError && <NonFieldError errorMessage={loginError} />}
            </div>
            <div className="flex flex-col-reverse sm:flex-row sm:justify-between items-center mt-8">
              <Link
                to="/forgot-password"
                className="inline-block text-gray-500 hover:text-gray-800 hover:underline"
              >
                {t("login.forgotPassword")}
              </Link>
              <Button type="submit" id="login">
                {t("login.loginButton")}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Login;
