import React from "react";

const Tab = (props) => {
  const styles = props.active ? "bg-white border-t-2" : "bg-wf-200";
  return (
    <button
      onClick={props.onClick}
      className={`font-bold h-12 flex items-center border-wf-blue p-3 ${styles}`}
    >
      {props.label}
    </button>
  );
};

export default Tab;
