import React from "react";
import { useOnClickOutside } from "../../hooks";

const Modal = (props) => {
  const { ref } = useOnClickOutside(props.onClose);

  // Do not render if the modal is closed
  if (!props.isOpen) {
    return null;
  }

  return (
    <div
      className="absolute z-50 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />
        <div
          ref={ref}
          className="inline-flex bg-white shadow-xl transform transition-all"
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
