import React, { useState } from "react";

import { useTogglable, useOnClickOutside } from "../../hooks";

import Label from "../Label";
import chevronDown from "url:../../assets/chevron_down.svg";
import chevronUp from "url:../../assets/chevron_up.svg";

const DropdownSelector = ({
  title,
  options,
  handleSelectedOption,
  defaultOption,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    defaultOption || options[0]
  );
  const dropDownMenu = useTogglable();
  const { ref } = useOnClickOutside(dropDownMenu.close);

  const handleSelect = (option) => {
    setSelectedOption(option);
    handleSelectedOption(option);
    dropDownMenu.close();
  };

  return (
    <div ref={ref} className="relative inline-block">
      <p className="text-sm text-wf-800 font-semibold mb-1.5">{title}</p>
      <div
        className="inline-flex items-center justify-between bg-wf-input border-b border-wf-500 text-wf-900 w-full p-3"
        onClick={dropDownMenu.toggle}
      >
        <Label className="placeholder-gray-600 w-full bg-transparent outline-none">
          {selectedOption}
        </Label>
        <img src={dropDownMenu.isOpen ? chevronUp : chevronDown} />
      </div>
      {dropDownMenu.isOpen && (
        <div className="absolute right-0 w-full mt-0 border-2 border-wf-blue">
          {options.map((option, index) => (
            <div
              key={index}
              className="bg-wf-100 p-3 text-wf-900 border-b border-wf-500"
              onClick={() => handleSelect(option)}
            >
              <Label className="placeholder-gray-600 w-full bg-transparent outline-none">
                {option}
              </Label>
            </div>
          ))}
        </div>
      )}
      <div className="origin-top-right absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"></div>
    </div>
  );
};

export default DropdownSelector;
