import React from "react";

import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { useUserUpdate } from "../../hooks/queries";
import Button from "../Button";
import TextInput from "../Input/TextInput";
import InputError from "../InputError";

const schema = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
});

const UserProfileForm = ({ user, refetchUser }) => {
  const { t } = useTranslation();
  const updateQuery = useUserUpdate(user.id);

  return (
    <div
      className="flext flex-col container w-90 m-auto mt-4"
      data-test="user-profile-form"
    >
      <Formik
        initialValues={{
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        }}
        onSubmit={async (values, actions) => {
          await updateQuery
            .mutateAsync({
              pk: user.id,
              first_name: values.first_name,
              last_name: values.last_name,
              email: user.email,
              role: user.role,
            })
            .then(() => {
              refetchUser();
            })
            .catch((error) => {
              actions.setErrors(error.response.data);
              console.log(error.response.data);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={schema}
      >
        <Form data-test="profile-form">
          <div className="container flex flex-col space-y-1 mb-4">
            <TextInput
              label={t("userProfile.profileForm.firstName")}
              name="first_name"
              backgroundColor="white"
            />
            <InputError name="first_name" />
            <TextInput
              label={t("userProfile.profileForm.lastName")}
              name="last_name"
              backgroundColor="white"
            />
            <InputError name="last_name" />
            <TextInput
              label="Email"
              name="email"
              backgroundColor="white"
              readOnly
            />
            <InputError name="email" />

            <InputError name="non_field_errors" />
          </div>
          <div className="flex justify-end mt-8">
            <Button type="submit" id="updateProfile">
              {t("userProfile.profileForm.submit")}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default UserProfileForm;
