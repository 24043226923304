import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Cropper from "../Cropper";

import Button from "../Button";
import Label from "../Label";
import InputError from "../InputError";
import DropdownSelector from "../DropDownSelector";
import {
  toFixedDecimalDigits,
  ZOOM_IN_FACTOR,
  ZOOM_OUT_FACTOR,
  MIN_ZOOM_FACTOR,
  MAX_ZOOM_FACTOR,
} from "../Cropper/utils";
import zoomInIcon from "url:../../assets/zoom_in.svg";
import zoomOutIcon from "url:../../assets/zoom_out.svg";
import viewIcon from "url:../../assets/view.svg";
import viewOffIcon from "url:../../assets/view_off.svg";

const schema = Yup.object({
  layer: Yup.string().required("Required"),
});

const ImageCropper = ({
  image,
  drawData,
  isTopLayer,
  onImageCropped,
  reset,
  partNumberID,
  setTouched,
}) => {
  const { t } = useTranslation();
  const layersNames = Object.keys(drawData.layers).sort();
  const topLayer = layersNames
    .map((l) => l.toLowerCase())
    .findIndex((l) => (l === isTopLayer ? "top" : "bottom"));
  const defaultLayer = topLayer === -1 ? layersNames[0] : layersNames[topLayer];
  const [imageToCrop, setImageToCrop] = useState(image);
  const [rotationAngle, setRotationAngle] = useState(0);
  const cropperRef = useRef();
  const [selectedLayer, setSelectedLayer] = useState(defaultLayer);
  const [zoomFactor, setZoomFactor] = useState(1);
  const [gridEnabled, setGridEnabled] = useState(false);
  const [profileEnabled, setProfileEnabled] = useState(true);
  const [mirrorX, _setMirrorX] = useState(false);
  const [mirrorY, _setMirrorY] = useState(false);
  const setMirrorX = val => {
    setTouched(true);
    _setMirrorX(val);
  }
  const setMirrorY = val => {
    setTouched(true);
    _setMirrorY(val);
  }

  const handleCrop = async () => {
    setTouched(false);
    try {
      const imageBlob = await cropperRef.current.crop();
      const extension = image.type === "image/png" ? "png" : "jpg";
      const imageFile = new File(
        [imageBlob],
        `part_${partNumberID}_${selectedLayer}.${extension}`,
        { type: image.type }
      );
      imageFile.type = image.type;
      setImageToCrop(imageFile);
      onImageCropped(imageFile);
      setZoomFactor(1);
      // _setMirrorX(false);
      // _setMirrorY(false);
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleZoom = (zoomChange) => {
    const newZoomFactor = toFixedDecimalDigits(zoomFactor + zoomChange, 1);
    if (newZoomFactor < MIN_ZOOM_FACTOR || newZoomFactor > MAX_ZOOM_FACTOR) {
      return;
    }
    setZoomFactor(newZoomFactor);
  };

  const handleRotationChange = ({ target: { value: angle } }) => {
    setRotationAngle(angle);
    cropperRef.current.rotate(angle);
  };

  return (
    <div className="inline-flex w-full">
      <div className="flex flex-col w-full">
        <div className="overflow-hidden p-4 w-full h-full">
          <Cropper
            className="m-auto"
            drawData={drawData}
            gridEnabled={gridEnabled}
            image={imageToCrop}
            layerName={selectedLayer}
            maxHeight={800}
            maxWidth={800}
            mirrorX={mirrorX}
            mirrorY={mirrorY}
            onImageCropped={() => setRotationAngle(0)}
            pointSize={5}
            profileEnabled={profileEnabled}
            ref={cropperRef}
            setTouched={setTouched}
            zoom={zoomFactor}
          />
        </div>
        <div className="inline-flex justify-between bg-white h-10 border-t border-wf-300">
          <div className="flex">
            <div className="flex w-10 h-full border-r border-wf-300">
              <button
                className="flex items-center place-content-center cursor-pointer h-full w-full"
                onClick={() => handleZoom(ZOOM_OUT_FACTOR)}
              >
                <img src={zoomOutIcon} />
              </button>
            </div>
            <Label className="flex w-13 text-sm text-wf-700 font-noto items-center place-content-center h-full border-r border-wf-300">
              {`${Math.trunc(zoomFactor * 100)}%`}
            </Label>
            <div className="flex w-10 h-full border-r border-wf-300">
              <button
                className="flex items-center place-content-center cursor-pointer h-full w-full"
                onClick={() => handleZoom(ZOOM_IN_FACTOR)}
              >
                <img src={zoomInIcon} />
              </button>
            </div>
          </div>
          <div className="flex">
            <button
              className="inline-flex items-center border-l border-r border-wf-300 px-3 space-x-1"
              onClick={() => setGridEnabled(!gridEnabled)}
            >
              <Label className="flex text-sm text-wf-800 font-noto font-semibold items-center h-full">
                Grid
              </Label>
              <img src={viewOffIcon} />
            </button>
            <button
              className="inline-flex items-center px-3 space-x-1"
              onClick={() => setProfileEnabled(!profileEnabled)}
            >
              <Label className="flex text-sm text-wf-800 font-noto font-semibold items-center h-full">
                Profile
              </Label>
              <img src={viewIcon} />
            </button>
            <button
              className="inline-flex items-center border-l border-r border-wf-300 px-3 space-x-1"
              onClick={() => setMirrorX(!mirrorX)}
            >
              <Label className="flex text-sm text-wf-800 font-noto font-semibold items-center h-full">
                Mirror X
              </Label>
            </button>
            <button
              className="inline-flex items-center border-l border-r border-wf-300 px-3 space-x-1"
              onClick={() => setMirrorY(!mirrorY)}
            >
              <Label className="flex text-sm text-wf-800 font-noto font-semibold items-center h-full">
                Mirror Y
              </Label>
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-white w-70 h-full border-l border-wf-300">
        <div className="flex mx-auto h-full">
          <div className="flex h-full">
            <Formik
              initialValues={{ layer: defaultLayer }}
              validationSchema={schema}
            >
              <div className="flex flex-col h-full">
                <Form
                  id="image-cropper-form"
                  data-test="general-information-form"
                >
                  <div className="container border-b border-wf-300 flex flex-col space-y-4 px-4 py-4">
                    <Label className="text-base text-wf-900 font-noto font-semibold">
                      {t(`partNumbers.wizard.imageCropper.outlineSettings`)}
                    </Label>
                    <DropdownSelector
                      title={"Layer"}
                      options={layersNames}
                      handleSelectedOption={setSelectedLayer}
                      defaultOption={selectedLayer}
                    />
                    <InputError name="layer" />
                  </div>
                  <div className="container border-b border-wf-300 flex flex-col space-y-4 px-4 py-4">
                    <Label className="text-base text-wf-900 font-noto font-semibold">
                      {t(`partNumbers.wizard.imageCropper.imageSettings`)}
                    </Label>
                    <div className="inline-flex items-center justify-between">
                      <p className="text-sm text-wf-800 font-semibold mb-1.5">
                        {t(`partNumbers.wizard.imageCropper.rotate`)}
                      </p>
                      <p className="text-sm text-wf-800 font-semibold mb-1.5">
                        {`${rotationAngle}°`}
                      </p>
                    </div>
                    <input
                      className="appearence-none"
                      type="range"
                      min="-180"
                      max="180"
                      value={rotationAngle}
                      onChange={handleRotationChange}
                    />
                  </div>
                </Form>
                <div className="flex justify-end bg-white px-4 py-6">
                  <button
                    className="text-xs text-wf-800 font-semibold px-3 mr-2"
                    onClick={reset}
                  >
                    {t(`partNumbers.wizard.imageCropper.resetAllButton`)}
                  </button>
                  <Button className="text-xs" black={true} onClick={handleCrop}>
                    {t(`partNumbers.wizard.imageCropper.cropImageButton`)}
                  </Button>
                </div>
              </div>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
