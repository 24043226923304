import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { useOnClickOutside, useTogglable } from "../../hooks";

const getInitials = (user) => {
  const first = user.first_name.charAt(0);
  const second = user.last_name.charAt(0);
  return first.toUpperCase() + second.toUpperCase();
};

/*
 * UserAvatar component.
 * A minimal round avatar which contains user's initials.
 */

/**
 * @param {object} user - The logged-in user.
 * @param {function} doLogout - The logout function.
 */

const UserAvatar = ({ user, doLogout, ...props }) => {
  const { t } = useTranslation();
  const dropDownMenu = useTogglable();
  const { ref } = useOnClickOutside(dropDownMenu.close);
  const backgroundColor = dropDownMenu.isOpen ? "bg-wf-800" : "bg-transparent";
  const menuStyle = `flex h-full items-center text-center text-sm text-wf-100 font-bold py-2 ${backgroundColor}`;

  return (
    <div className="flex h-full" data-test="useravatar-component" ref={ref}>
      <div className="relative">
        <button className={menuStyle} onClick={dropDownMenu.toggle}>
          <div className="flex items-center rounded-full bg-wf-600 border-2 border-wf-100 h-8 w-8">
            <div className="m-auto">{getInitials(user)}</div>
          </div>
        </button>
        {dropDownMenu.isOpen && (
          <div className="absolute right-0 w-auto mt-0 bg-white border shadow-xl">
            <Link
              to="#"
              onClick={dropDownMenu.close}
              className="flex flex-col p-4 text-wf-900 whitespace-nowrap"
            >
              <div className="text-base text-wf-900">{`${user.first_name} ${user.last_name}`}</div>
              <div className="text-sm text-wf-700">{`${user.email}`}</div>
            </Link>
            <hr></hr>
            <Link
              to="/account/profile"
              onClick={dropDownMenu.close}
              className="flex p-4 text-wf-900 font-bold whitespace-nowrap"
            >
              <div className="text-sm text-wf-800">
                {t("header.userDropDown.viewAccount")}
              </div>
            </Link>
            <hr></hr>
            <button
              onClick={doLogout}
              className="flex p-4 text-wf-900 whitespace-nowrap"
            >
              <div className="text-sm text-wf-700">
                {t("header.userDropDown.logout")}
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAvatar;
