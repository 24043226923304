import React from "react";
import { NavLink } from "react-router-dom";

export const HeaderLink = ({ to, children, ...props }) => (
  <NavLink
    to={to}
    exact
    activeClassName="header-link font-semibold py-4 mx-2"
    className="font-semibold text-base py-4 mx-4"
    {...props}
  >
    {children}
  </NavLink>
);
