import React from "react";

import { useTranslation } from "react-i18next";
import { Switch, Route } from "react-router-dom";

import Container from "../../components/Container";
import Breadcrumbs from "../../components/Breadcrumbs";
import { UserNavLink } from "../../components/UserNavLink";
import UserProfileForm from "../../components/UserProfileForm";
import UserPasswordForm from "../../components/UserPasswordForm";

const UserProfile = ({ user, refetchUser }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col flex-auto" data-test="user-profile-page">
      <Breadcrumbs levelsToSkip={1}>
        <div className="flex flex-col">
          <div className="py-4 text-xl font-semibold text-wf-900">
            {t("userProfile.pageTitle")}
          </div>
          <div className="flex flex-row">
            <UserNavLink to={"/account/profile"}>
              {t("userProfile.profileTab")}
            </UserNavLink>
            <UserNavLink to={"/account/password"}>
              {t("userProfile.passwordTab")}
            </UserNavLink>
          </div>
        </div>
      </Breadcrumbs>
      <Container>
        <Switch>
          <Route path="/account/profile">
            <UserProfileForm user={user} refetchUser={refetchUser} />
          </Route>
          <Route exact path="/account/password">
            <UserPasswordForm />
          </Route>
        </Switch>
      </Container>
    </div>
  );
};

export default UserProfile;
