import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

/*
 * Hook used to add an `expanded` property to each result.
 */
export const useData = (lastWorkSessions) => {
  const { t } = useTranslation();
  const data = lastWorkSessions || [];

  const columns = useMemo(() => [
    {
      Header: t("partNumbers.expandedView.serialNumber"),
      accessor: "serial_number",
    },
    {
      Header: t("partNumbers.expandedView.user"),
      accessor: (originalRow) => (
        originalRow["most_recent_modification"]?.[0]?.["latest_modification"]?.[0]?.["full_name"] || originalRow["full_name"]
      ),
      Cell: ({ value }) => (
        <div className="text-wf-900 font-semibold">{value}</div>
      ),
    },
    {
      Header: t("partNumbers.expandedView.description"),
      accessor: (originalRow) => {
        const mostRecentModification = originalRow["most_recent_modification"]?.[0];
        if(mostRecentModification) {
          return mostRecentModification["description"];
        }
        return originalRow["description"];
      }
    },
    {
      Header: t("partNumbers.expandedView.submitDate"),
      accessor: "most_recent_modification[0].latest_modification[0].created",
      Cell: ({ value }) => {
        if (value) {
          return (
            <>
              {new Intl.DateTimeFormat("default", {
                timeStyle: "medium",
                dateStyle: "short",
              }).format(new Date(value))}
            </>
          );
        }
        return <></>;
      },
    },
  ]);

  return { data, columns };
};
