import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../components/Breadcrumbs";
import FilterBar from "../../components/FilterBar";
import Container from "../../components/Container";
import DataTable from "../../components/DataTable";
import Error from "../../components/Error";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import UserForm from "../../components/UserForm";

import { useTogglable } from "../../hooks";
import { useData } from "./hooks";

const UsersList = ({ user }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [orderingField, setOrderingField] = useState("");
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const { isLoading, error, data, columns, pageCount } = useData({
    queryParams: { page: currentPage, ordering: orderingField, search, role },
    user,
  });
  const modal = useTogglable();

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      <Breadcrumbs>
        <div className="py-4 text-xl font-semibold text-wf-900">
          {t("usersList.pageTitle")}
        </div>
      </Breadcrumbs>
      <Container>
        <FilterBar
          onSearch={setSearch}
          search={search}
          selects={[
            {
              value: role,
              onChange: setRole,
              options: [
                { value: "BCK", label: t("userRoles.backoffice") },
                { value: "ADM", label: t("userRoles.admin") },
                { value: "OPE", label: t("userRoles.operator") },
              ],
            },
          ]}
          onCreate={modal.open}
          buttonLabel={t("usersList.newUserButton")}
        />
        <Modal isOpen={modal.isOpen} onClose={modal.close}>
          <UserForm closeModal={modal.close} />
        </Modal>
        <DataTable
          dataTest="users-table"
          data={data}
          columns={columns}
          pageCount={pageCount}
          setNextPage={() => setCurrentPage(currentPage + 1)}
          setPreviousPage={() => setCurrentPage(currentPage - 1)}
          setPage={setCurrentPage}
          orderBy={setOrderingField}
        />
      </Container>
    </>
  );
};

export default UsersList;
