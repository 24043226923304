import React from "react";

import GeneralInformationForm from "../GeneralInformationForm";
import DesignFileUploadForm from "../DesignFileUploadForm";
import ImageUploadForm from "../ImageUploadForm";
import PcbBoardInfoForm from "../PcbBoardInfoForm";
import { PN_WIZARD_STEPS_COUNT } from "../../utils";

const PartNumberWizardPageController = ({
  setCreate,
  currentPage,
  setCurrentPage,
  partNumberID,
  selectedPartNumber,
  setPartNumberID,
  closeWizard,
  onCreationComplete,
  setWizardStepProgress,
}) => {
  switch (currentPage) {
    case 1:
      return (
        <GeneralInformationForm
          selectedPartNumber={selectedPartNumber}
          setPartNumberId={setPartNumberID}
          goToNextPage={() => {
            setCreate(false);
            setCurrentPage(2);
          }}
          closeWizard={closeWizard}
          wizardStep={1}
          wizardStepsCount={PN_WIZARD_STEPS_COUNT}
          setWizardStepProgress={setWizardStepProgress}
        />
      );
    case 2:
      return (
        <DesignFileUploadForm
          partNumberID={partNumberID}
          selectedPartNumber={selectedPartNumber}
          goToNextPage={() => setCurrentPage(3)}
          closeWizard={closeWizard}
          wizardStep={2}
          wizardStepsCount={PN_WIZARD_STEPS_COUNT}
          setWizardStepProgress={setWizardStepProgress}
        />
      );
    case 3:
      return (
        <ImageUploadForm
          partNumberID={partNumberID}
          selectedPartNumber={selectedPartNumber}
          goToNextPage={() => setCurrentPage(4)}
          closeWizard={closeWizard}
          topImage={true}
          wizardStep={3}
          wizardStepsCount={PN_WIZARD_STEPS_COUNT}
          setWizardStepProgress={setWizardStepProgress}
        />
      );
    case 4:
      return (
        <ImageUploadForm
          partNumberID={partNumberID}
          selectedPartNumber={selectedPartNumber}
          goToNextPage={() => setCurrentPage(5)}
          closeWizard={closeWizard}
          topImage={false}
          wizardStep={4}
          wizardStepsCount={PN_WIZARD_STEPS_COUNT}
          setWizardStepProgress={setWizardStepProgress}
        />
      );
    case 5:
      return (
        <PcbBoardInfoForm
          partNumberID={partNumberID}
          selectedPartNumber={selectedPartNumber}
          closeWizard={closeWizard}
          onCreationComplete={onCreationComplete}
          wizardStep={5}
          wizardStepsCount={PN_WIZARD_STEPS_COUNT}
        />
      );
  }
};

export default PartNumberWizardPageController;
