import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Field, Formik, Form } from "formik";

import Breadcrumbs from "../../components/Breadcrumbs";
import Button from "../../components/Button";
import FilterBar from "../../components/FilterBar";
import Container from "../../components/Container";
import DataTable from "../../components/DataTable";
import Error from "../../components/Error";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";

import { useTogglable } from "../../hooks";
import { useData } from "./hooks";

import {
  useCreateProject,
  useProjectDetail,
  useUpdateProject,
} from "../../hooks/queries";
import TextInput from "../../components/Input/TextInput";
import ColorPicker from "../../components/ColorPicker";
import EmptyState from "../../components/EmptyState";
import projectsEmptyStateImage from "url:../../assets/projects_empty_state.png";

export const ProjectForm = (props) => {
  const createProject = useCreateProject();
  const updateProject = useUpdateProject(props.initialValues?.id);
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        name: props.initialValues?.name || "",
        color: props.initialValues?.color || "#000000",
      }}
      onSubmit={async (values, actions) => {
        await (props.initialValues?.id ? updateProject : createProject)
          .mutateAsync(values)
          .then((response) => {
            actions.resetForm();
            props.handleProjectCreated
              ? props.handleProjectCreated(response.data)
              : props.closeModal();
          })
          .catch((error) => {
            console.log(error);
            actions.setErrors(error);
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      <Form className="flex-col border-t-4 border-wf-blue p-6">
        <div className="font-bold text-xl py-4">
          {props.initialValues?.id
            ? t("projectsList.form.headerUpdate")
            : t("projectsList.form.header")}
        </div>
        <div className="flex flex-row justify-between">
          <TextInput label="Name" name="name" className="py-2 w-119" />
          <Field name="color">{(props) => <ColorPicker {...props} />}</Field>
        </div>
        <Button secondary className="mr-2" onClick={props.closeModal}>
          {t("projectsList.form.cancelButton")}
        </Button>
        <Button type="submit">
          {props.initialValues?.id
            ? t("projectsList.form.confirmUpdate")
            : t("projectsList.form.confirmButton")}
        </Button>
      </Form>
    </Formik>
  );
};

const ProjectsList = ({ user }) => {
  const { t } = useTranslation();
  const history = useHistory();

  // Projects list
  const [currentPage, setCurrentPage] = useState(1);
  const [orderingField, setOrderingField] = useState("");
  const [search, setSearch] = useState("");
  const { isLoading, error, data, columns, pageCount } = useData({
    withPagination: true,
    queryParams: { page: currentPage, ordering: orderingField, search },
    user,
    setProject: (project) => history.push(`/projects/${project.id}`),
  });

  // Project detail
  const [selectedProject, _setProject] = useState(null);
  const { id } = useParams();
  const setProject = (project) => {
    _setProject(project);
    if (project?.id) {
      history.push(`/projects/${project.id}`);
    } else {
      history.push("/projects");
    }
  };

  const modal = useTogglable({ closeCb: () => setProject(null) });
  const projectQuery = useProjectDetail(id, { enabled: id !== undefined });

  useEffect(() => {
    // If the query has some new data, call setProject
    if (projectQuery.data?.data.id) {
      if (selectedProject?.id !== projectQuery.data?.data.id) {
        setProject(projectQuery.data?.data);
        modal.open();
      }
    }
  }, [projectQuery.data?.data.id]);

  // Rendering
  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      <Breadcrumbs>
        <div className="py-4 text-xl text-wf-900">
          {t("projectsList.breadcrumbsTitle")}
        </div>
      </Breadcrumbs>
      {ReactDOM.createPortal(
        <Modal isOpen={modal.isOpen} onClose={modal.close}>
          <ProjectForm
            closeModal={modal.close}
            initialValues={selectedProject}
          />
        </Modal>,
        document.body
      )}
      <Container>
        {search === "" && (data.length === 0 || data.length === undefined) ? (
          <EmptyState
            image={projectsEmptyStateImage}
            label={t("projectsList.emptyStateLabel")}
            buttonLabel={t("projectsList.newProjectButton")}
            buttonAction={modal.open}
          />
        ) : (
          <>
            <FilterBar
              onSearch={setSearch}
              search={search}
              onCreate={modal.open}
              selects={[]}
              buttonLabel={t("projectsList.newProjectButton")}
            />
            <DataTable
              dataTest="projects-table"
              data={data}
              columns={columns}
              pageCount={pageCount}
              setNextPage={() => setCurrentPage(currentPage + 1)}
              setPreviousPage={() => setCurrentPage(currentPage - 1)}
              setPage={setCurrentPage}
              orderBy={setOrderingField}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default ProjectsList;
