import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useUsers, useUserDelete } from "../../hooks/queries";
import { useTogglable } from "../../hooks";

import DeleteModal from "../../components/DeleteModal";
import trashCan from "url:../../assets/trash_can.svg";
import { USER_ROLES } from "../../utils";

/*
 * Hook used to retrieve all info about users.
 */
export const useData = ({ queryParams, user }) => {
  const response = useUsers(queryParams);
  const { t } = useTranslation();
  const data = response.data?.data.results || [];
  const [pageCount, setPageCount] = useState(
    Math.ceil(response.data?.data.count / 10)
  );
  useEffect(() => {
    setPageCount(Math.ceil(response.data?.data.count / 10));
  }, [response.data?.data.count]);

  const columns = useMemo(() => [
    { Header: t("usersList.header.firstName"), accessor: "first_name" },
    { Header: t("usersList.header.lastName"), accessor: "last_name" },
    { Header: t("usersList.header.email"), accessor: "email" },
    { Header: t("usersList.header.role"), accessor: "role" },
    {
      id: "deleteColumn",
      Header: (_) => null,
      Cell: ({ row }) => {
        const modal = useTogglable();
        const userDelete = useUserDelete(row.original.id);

        const handleDeleteAction = () => {
          userDelete
            .mutateAsync()
            .then(modal.close)
            .catch((error) => console.log(error.response.data));
        };

        if (user.role != USER_ROLES.admin || row.original.id == user.id) {
          return null;
        }

        return (
          <>
            <DeleteModal
              isOpen={modal.isOpen}
              closeModal={modal.close}
              deleteAction={handleDeleteAction}
              objectType={t("user")}
              objectName={row.original.full_name}
            />
            <button onClick={modal.open} className="w-10 p-0">
              <img className="trashCan" src={trashCan} />
            </button>
          </>
        );
      },
    },
  ]);

  return { ...response, data, columns, pageCount };
};
