export const getSize = (polygon) => {
  let minx = polygon.begin[0];
  let miny = polygon.begin[1];
  let maxx = polygon.begin[0];
  let maxy = polygon.begin[1];
  for (const step of polygon.steps) {
    if (step.end[0] < minx) minx = step.end[0];
    if (step.end[1] < miny) miny = step.end[1];
    if (step.end[0] > maxx) maxx = step.end[0];
    if (step.end[1] > maxy) maxy = step.end[1];
  }
  return {
    width: maxx - minx,
    height: maxy - miny,
    minx,
    miny,
  };
};
