import React from "react";

import { useTable, useExpanded, usePagination, useSortBy } from "react-table";

import ExpandedRow from "../ExpandedRow";
import arrowUp from "url:../../assets/arrow_up.svg";
import arrowDown from "url:../../assets/arrow_down .svg";

const DataTable = ({
  columns,
  data,
  pageCount,
  setNextPage,
  setPreviousPage,
  setPage,
  orderBy,
  dataTest,
}) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    page,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    // Get the state from the instance
    state: { pageIndex },
  } = tableInstance;

  return (
    <>
      <table
        className="border w-full"
        {...getTableProps()}
        data-test={dataTest}
      >
        <thead className="divide-y">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                if (column.isSorted) {
                  const orderingField = column.isSortedDesc
                    ? `-${column.id}`
                    : `${column.id}`;
                  orderBy(orderingField);
                }
                return (
                  <th
                    className="bg-wf-200 px-3 py-4 font-semibold text-left"
                    style={{ width: column.width }}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="inline-flex items-center">
                      {column.render("Header")}
                      <span className="ml-2">
                        <img
                          src={
                            column.isSorted
                              ? column.isSortedDesc
                                ? arrowDown
                                : arrowUp
                              : ""
                          }
                        />
                      </span>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}
        <tbody className="bg-white" {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps();
            return (
              <React.Fragment key={key}>
                <tr className="row" {...rowProps}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className="border-t border-wf-200 px-3 py-4"
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded && (
                  <tr>
                    <td></td>
                    <td
                      className="px-3 border-t"
                      colSpan={`${row.cells.length - 2}`}
                    >
                      <ExpandedRow rowData={row.original} />
                    </td>
                    <td></td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr className="bg-white">
            <td className="p-0 border-t" colSpan="100">
              <div className="flex justify-end">
                <div className="p-4 border-l">
                  <select
                    className="bg-white"
                    value={pageIndex + 1}
                    onChange={({ target: { value } }) => {
                      gotoPage(value - 1);
                      setPage(value);
                    }}
                  >
                    {Array.from(Array(pageOptions.length), (_, i) => (
                      <option key={`page-${i + 1}`} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                  <span> of {pageOptions.length} pages</span>
                </div>
                <div>
                  <button
                    className="p-4 border-l"
                    onClick={() => {
                      previousPage();
                      setPreviousPage();
                    }}
                    disabled={!canPreviousPage}
                  >
                    {" < "}
                  </button>
                  <button
                    className="p-4 border-l"
                    onClick={() => {
                      nextPage();
                      setNextPage();
                    }}
                    disabled={!canNextPage}
                  >
                    {" > "}
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default DataTable;
