import React from "react";

import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { usePasswordUpdate } from "../../hooks/queries";
import Button from "../Button";
import TextInput from "../Input/TextInput";
import InputError from "../InputError";

const schema = Yup.object({
  old_password: Yup.string().required("This field is required"),
  new_password1: Yup.string().required("This field is required"),
  new_password2: Yup.string().required("This field is required"),
});

const UserPasswordForm = () => {
  const { t } = useTranslation();
  const passwordUpdateQuery = usePasswordUpdate();

  return (
    <div
      className="flext flex-col container w-90 m-auto mt-4"
      data-test="user-password-form"
    >
      <Formik
        initialValues={{
          old_password: "",
          new_password1: "",
          new_password2: "",
        }}
        onSubmit={async (values, actions) => {
          await passwordUpdateQuery
            .mutateAsync({
              old_password: values.old_password,
              new_password1: values.new_password1,
              new_password2: values.new_password2,
            })
            .catch((error) => {
              actions.setErrors(error.response.data);
              console.log(error.response.data);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={schema}
      >
        <Form data-test="password-form">
          <div className="container flex flex-col space-y-1 mb-4">
            <TextInput
              label={t("userProfile.passwordForm.oldPassword")}
              name="old_password"
              backgroundColor="white"
              password
            />
            <InputError name="old_password" />
            <TextInput
              label={t("userProfile.passwordForm.newPassword")}
              name="new_password1"
              backgroundColor="white"
              password
            />
            <InputError name="new_password1" />
            <TextInput
              label={t("userProfile.passwordForm.confirmNewPassword")}
              name="new_password2"
              backgroundColor="white"
              password
            />
            <InputError name="new_password2" />
          </div>
          <div className="flex justify-end mt-8">
            <Button type="submit" id="updatePassword">
              {t("userProfile.passwordForm.submit")}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default UserPasswordForm;
