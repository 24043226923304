import React from "react";

import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { humanize } from "../../utils";
import homeIcon from "url:../../assets/home_icon.png";

const Breadcrumbs = ({ levelsToSkip, ...props }) => {
  const history = useHistory();

  const path = history.location.pathname;

  let crumbs = path.split("/").filter((crumb) => crumb !== "");
  crumbs = crumbs.slice(levelsToSkip);

  return (
    <div className="flex flex-col bg-white pt-4 font-noto w-full border-b-2 border-wf-300 px-34">
      <nav className="flex">
        <ol className="items-center list-reset flex text-wf-900">
          <li key="home">
            <Link to="/">
              <img className="h-4 w-4" src={homeIcon} />
            </Link>
          </li>
          {crumbs.map((crumb, index) => {
            const isLastItem = index == crumbs.length - 1;
            const customBehaviour = isLastItem
              ? "text-wf-900 pointer-events-none font-bold"
              : "text-wf-blue font-bold";
            return (
              <li key={crumb}>
                <span className="text-sm text-wf-700 mx-2 font-bold">/</span>
                {props.skipLinks.includes(crumb) ? (
                  humanize(crumb)
                ) : (
                  <Link
                    to={`/${crumbs.slice(0, index + 1).join("/")}`}
                    className={customBehaviour}
                  >
                    {humanize(crumb)}
                  </Link>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
      {props.children}
    </div>
  );
};

Breadcrumbs.defaultProps = {
  levelsToSkip: 0,
  skipLinks: [],
};

Breadcrumbs.propTypes = {
  levelsToSkip: PropTypes.number,
  skipLinks: PropTypes.arrayOf(PropTypes.string),
};

export default Breadcrumbs;
