import React from "react";

import { useTranslation } from "react-i18next";

import closeButtonIcon from "url:../../assets/close_icon.svg";
import Button from "../Button";
import Label from "../Label";
import Modal from "../Modal";

const DeleteModal = ({
  isOpen,
  closeModal,
  deleteAction,
  objectType,
  objectName,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className="flex border-wf-blue border-t-4">
        <button
          className="absolute right-0 top-0 h-3 w-3 mt-4 mr-3"
          onClick={closeModal}
        >
          <img src={closeButtonIcon} />
        </button>
        <div className="flex flex-col">
          <div className="flex flex-col container p-8">
            <Label className="flex font-noto font-bold text-2xl text-wf-900 mr-12.5">
              {t("deleteModal.title", { objectType: objectType })}
            </Label>
            <Label className="flex font-noto text-base text-wf-900 mt-8">
              {t("deleteModal.message", { objectName: objectName })}
            </Label>
          </div>
          <div className="flex justify-end bg-wf-input h-20 px-8 py-4 space-x-2">
            <Button onClick={() => closeModal()} secondary={true}>
              {t("deleteModal.cancelButton")}
            </Button>
            <Button onClick={() => deleteAction()} red={true}>
              {t("deleteModal.deleteButton", { objectType: objectType })}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
