import React, { useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../components/Container";
import MarkersList from "../../components/MarkersList";
import Tab from "../../components/Tab";

import UserIcon from "url:../../assets/user.svg";
import PartNumberIcon from "url:../../assets/part-number.svg";
import MarkerIcon from "url:../../assets/Marker.svg";
import MarkerSelectedIcon from "url:../../assets/Marker-selected.svg";

import { useWorkSessionDetail, usePartNumberDetail } from "../../hooks/queries";
import { useTogglable } from "../../hooks";

const MarkerPin = (props) => {
  return (
    <div
      className="absolute w-10 h-10 cursor-pointer"
      style={{
        // 20 is half size of the icon, this way we center it on the point
        top: props.top - 20,
        left: props.left - 20,
        backgroundImage: `url(${
          props.selected ? MarkerSelectedIcon : MarkerIcon
        })`,
      }}
      onClick={props.onClick}
    />
  );
};

const useMarkerDetail = () => {
  const detail = useTogglable();
  const menu = useTogglable();
  const [marker, setMarker] = useState(null);

  const open = (marker) => {
    detail.open();
    setMarker(marker);
  };

  const close = () => {
    detail.close();
    setMarker(null);
  };
  return {
    detail: {
      ...detail,
      open,
      close,
      marker,
    },
    menu,
  };
};

const WorkSessionDetail = () => {
  const { t } = useTranslation();
  const { id, partNumberId } = useParams();
  const workSessionQuery = useWorkSessionDetail(id, { retry: 1 });
  const partNumberQuery = usePartNumberDetail(partNumberId, { retry: 1 });
  const [selectedImage, setSelectedImage] = useState("top");
  const markersControls = useMarkerDetail();
  const [imgSize, setImgSize] = useState();

  const workSession = workSessionQuery.data?.data;
  const partNumber = partNumberQuery.data?.data;

  if (workSessionQuery.isLoading) {
    return null;
  }

  if (workSessionQuery.isError) {
    return <Redirect to={$`/part-numbers/${partNumberId}`} />;
  }

  return (
    <>
      <Breadcrumbs skipLinks={["work-sessions"]}>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col">
            <div className="py-2 text-xl font-bold text-wf-900">
              {t("workSessionDetail.workSession")} {workSession.serial_number}
            </div>
            <div className="flex flex-row py-2">
              <img src={UserIcon} className="mr-1" />
              <div className="text-wf-900 mr-2 text-sm">
                {workSession.created_by || t("workSessionDetail.unknownUser")}
              </div>
              <img src={PartNumberIcon} className="mr-1" />
              <div className="text-wf-900 text-sm">
                {partNumber?.name || ""}
              </div>
            </div>
            <div className="text-wf-700 pt-2 pb-4">
              {workSession.description}
            </div>
          </div>
        </div>
      </Breadcrumbs>
      <Container>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col w-2/5">
            <div className="flex flex-row">
              <Tab
                label={t("workSessionDetail.topImage")}
                active={selectedImage === "top"}
                onClick={() => setSelectedImage("top")}
              />
              <Tab
                label={t("workSessionDetail.bottomImage")}
                active={selectedImage === "bottom"}
                onClick={() => setSelectedImage("bottom")}
              />
            </div>
            <div className="relative w-100">
              <img
                className="absolute top-0 left-0"
                onLoad={({ target: img }) => {
                  setImgSize([img.width, img.height]);
                }}
                src={
                  selectedImage === "top"
                    ? partNumber?.top_image
                    : partNumber?.bottom_image
                }
              />
              {partNumber &&
                imgSize &&
                workSession.markers.filter(m => m.side === selectedImage).map((marker) => {
                  const { width: pnW, height: pnH } = partNumber;
                  const [imgW, imgH] = imgSize;
                  const x = (marker.transform.position_x * imgW) / pnW;
                  const y = (marker.transform.position_y * imgH) / pnH;
                  const top = imgH - y;
                  const left = selectedImage === "bottom" ? imgW - x : x;
                  return (
                    <MarkerPin
                      key={`marker-${marker.id}`}
                      selected={
                        markersControls.detail.isOpen &&
                        markersControls.detail.marker.id === marker.id
                      }
                      onClick={() => markersControls.detail.open(marker)}
                      top={top}
                      left={left}
                      shape={marker.shape}
                      scaleX={marker.transform.scale_x}
                      scaleY={marker.transform.scale_y}
                    />
                  );
                })}
            </div>
          </div>
          <MarkersList
            markers={workSession.markers}
            controls={markersControls}
          />
        </div>
      </Container>
    </>
  );
};

export default WorkSessionDetail;
