import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";

import Button from "../Button";
import Label from "../Label";
import TextInput from "../Input/TextInput";
import InputError from "../InputError";
import ProjectDropdownSelector from "../ProjectDropdownSelector/index";
import {
  useCreatePartNumber,
  useWizardUpdatePartNumber,
  useProjectsSearch,
} from "../../hooks/queries";
import NonFieldError from "../NonFieldError";

const defaultInitialValues = {
  name: "",
  code: "",
  status: "DR",
  project: undefined,
  description: "",
};

const GeneralInformationForm = ({
  minimal,
  selectedPartNumber,
  setPartNumberId,
  goToNextPage,
  closeWizard,
  wizardStep,
  wizardStepsCount,
  setWizardStepProgress,
}) => {
  const { t } = useTranslation();
  // Queries
  const createPartNumber = useCreatePartNumber();
  const updatePartNumber = useWizardUpdatePartNumber(selectedPartNumber?.id);
  const [genericError, setGenericError] = useState(null);
  // Search query for projects dropdown, defaults to empty
  // string to retrieve all projects
  const [searchQuery, setSearchQuery] = useState("");
  const projectQuery = useProjectsSearch({ name: searchQuery });
  const projects = projectQuery.data?.data || [];
  const [selectedProject, setSelectedProject] = useState(null);
  const [saveAndExit, setSaveAndExit] = useState(false);

  const onSubmit = async (values, actions) => {
    const query = selectedPartNumber ? updatePartNumber : createPartNumber;
    await query
      .mutateAsync({ ...values, wizard_step: wizardStep })
      .then((response) => {
        console.log("Success: ", response);
        setPartNumberId(response.data.id);
        if (saveAndExit === true) {
          closeWizard();
        } else {
          setWizardStepProgress(wizardStep + 1);
          goToNextPage();
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        const data = error.response?.data;
        actions.setErrors(data);
        if (data?.detail) {
          setGenericError(data.detail);
        }
      });
  };

  return (
    <div className="flex mx-auto">
      <div className="container border border-wf-300">
        {!minimal && (
          <div className="flex border-b border-wf-300 px-8 py-4">
            <Label className="text-base text-wf-900 font-noto font-semibold">
              {t("partNumbers.wizard.generalInformationForm.formTitle", {
                currentStep: wizardStep,
                wizardStepsCount: wizardStepsCount,
              })}
            </Label>
          </div>
        )}
        <div className="flex">
          <Formik
            initialValues={
              selectedPartNumber
                ? {
                    ...selectedPartNumber,
                    project: selectedPartNumber.project?.id,
                  }
                : defaultInitialValues
            }
            onSubmit={onSubmit}
          >
            {(actions) => (
              <div className="flex flex-col">
                <Form
                  id="general-information-form"
                  data-test="general-information-form"
                >
                  <div className="container flex flex-col space-y-4 px-8 py-8">
                    <TextInput
                      label={t(
                        "partNumbers.wizard.generalInformationForm.title"
                      )}
                      name="name"
                      hint={t(
                        "partNumbers.wizard.generalInformationForm.titleHint"
                      )}
                      backgroundColor="gray"
                    />
                    <InputError name="name" />
                    <TextInput
                      label={t(
                        "partNumbers.wizard.generalInformationForm.code"
                      )}
                      name="code"
                      hint={t(
                        "partNumbers.wizard.generalInformationForm.codeHint"
                      )}
                      backgroundColor="gray"
                    />
                    <InputError name="code" />
                    <ProjectDropdownSelector
                      projects={projects}
                      selectedProject={
                        selectedProject || selectedPartNumber?.project
                      }
                      setSearchQuery={setSearchQuery}
                      onSelect={(project) => {
                        setSelectedProject(project);
                        actions.setFieldValue(
                          "project",
                          project ? project.id : null
                        );
                      }}
                    />
                    <InputError name="project" />
                    <TextInput
                      label={t(
                        "partNumbers.wizard.generalInformationForm.description"
                      )}
                      name="description"
                      hint={t(
                        "partNumbers.wizard.generalInformationForm.descriptionHint"
                      )}
                      backgroundColor="gray"
                    />
                    <InputError name="description" />
                    {genericError && (
                      <NonFieldError errorMessage={genericError} />
                    )}
                  </div>
                </Form>
                <div className="flex justify-end bg-wf-input border-t border-wf-300 h-20 px-8 py-4">
                  {!minimal && (
                    <button
                      disabled={createPartNumber.isLoading}
                      className="border-wf-900 border-4 px-3 mr-2"
                      onClick={() => {
                        setSaveAndExit(true);
                        actions.submitForm();
                      }}
                    >
                      {t(
                        "partNumbers.wizard.generalInformationForm.saveAsDraftButton"
                      )}
                    </button>
                  )}
                  <Button
                    disabled={createPartNumber.isLoading}
                    onClick={actions.submitForm}
                  >
                    {t(
                      "partNumbers.wizard.generalInformationForm.saveAndProceedButton"
                    )}
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default GeneralInformationForm;
