import Button from "../../components/Button";

const FilterBar = ({ search, onSearch, onCreate, buttonLabel, selects }) => {
  return (
    <div className="py-4 w-full">
      <div className="flex w-full justify-between">
        <div className="flex text-wf-600 w-4/5 h-14">
          <div className="w-14 h-14 px-4 flex border-b border-wf-300 focus:outline-none bg-white placeholder-wf-400">
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              className="w-14"
            >
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
          <input
            autoComplete="off"
            className="w-full text-wf-700 text-sm py-3 border-b border-wf-300 focus:outline-none bg-white-100 placeholder-gray-600"
            placeholder="Type to search..."
            name="search"
            value={search}
            onChange={(e) => onSearch(e.target.value)}
          />
          {selects.map(({ value, onChange, options }, index) => (
            <select
              key={index}
              className="w-full border-b border-l border-wf-300 px-2 py-3 bg-white"
              value={value}
              onChange={(e) => onChange(e.target.value)}
            >
              <option value="">All</option>
              {options.map((o) => (
                <option key={`${o.value}-${o.label}`} value={o.value}>
                  {o.label}
                </option>
              ))}
            </select>
          ))}
        </div>
        {/* TODO: check why it crashes if the function is not redefined */}
        <Button onClick={() => onCreate()} className="">
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};

export default FilterBar;
