import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

/* TextInput component, used in forms.
 * @param {String} label - Label
 * @param {String} name - Field name
 */
const TextInput = ({
  label,
  name,
  hint,
  password,
  backgroundColor,
  className: extraClassName,
  ...props
}) => {
  const className = extraClassName || "";
  let bgColor = "";
  switch (backgroundColor) {
    case "gray":
      bgColor = "bg-wf-input";
      break;
    case "white":
      bgColor = "bg-white";
  }

  const placeholder = hint ? hint : label;

  return (
    <div className={className}>
      <p htmlFor={name} className="text-sm text-wf-800 font-semibold mb-1.5">
        {label}
      </p>
      <Field
        {...props}
        className={`w-full p-3 border-b border-wf-500 focus:outline-none ${bgColor} placeholder-gray-600`}
        type={password ? "password" : "text"}
        placeholder={placeholder}
        name={name}
      />
    </div>
  );
};

TextInput.defaultProps = {
  backgroundColor: "gray",
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  password: PropTypes.bool,
  backgroundColor: PropTypes.oneOf(["gray", "white"]),
};

export default TextInput;
