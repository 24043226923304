import React, { useState, useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Button from "../Button";
import Label from "../Label";
import InputError from "../InputError";
import {
  useWizardUpdatePartNumber,
  usePartNumberDetail,
} from "../../hooks/queries";
import { downloadFile } from "../../utils";
import dragNDropIcon from "url:../../assets/drag_n_drop.svg";
import closeIcon from "url:../../assets/close_icon.svg";

const schema = Yup.object({
  width: Yup.number().min(2, "Too short!").required("This field is required"),
  height: Yup.number().min(2, "Too short!").required("This field is required"),
});

const PcbBoardInfoForm = ({
  partNumberID,
  closeWizard,
  onCreationComplete,
  wizardStep,
  wizardStepsCount,
  selectedPartNumber,
  minimal,
}) => {
  const { t } = useTranslation();
  const [fileToUpload, setFileToUpload] = useState(null);
  const updatePartNumber = useWizardUpdatePartNumber(partNumberID);
  const onDrop = useCallback((acceptedFiles) => {
    for (let file of acceptedFiles) {
      setFileToUpload(file);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
    accept: "text/csv",
  });
  const [drawData, setDrawData] = useState(null);
  const partNumberDetail = usePartNumberDetail(partNumberID);
  useEffect(() => {
    if (partNumberDetail.isSuccess) {
      setDrawData(partNumberDetail.data.data.draw_data);
    }
  }, [partNumberDetail]);

  useEffect(() => {
    setFileToUpload(null);
    if (selectedPartNumber?.bom) {
      downloadFile(selectedPartNumber.bom, "text/csv")
        .then((file) => {
          setFileToUpload(file);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleSubmit = async (onSubmit, onUpdateComplete) => {
    const formData = new FormData();
    if (fileToUpload) {
      formData.append("bom", fileToUpload);
    }
    formData.append("width", onSubmit.values.width);
    formData.append("height", onSubmit.values.height);
    formData.append("wizard_step", wizardStep);
    await updatePartNumber
      .mutateAsync(formData)
      .then(onUpdateComplete())
      .catch((error) => {
        console.log(error);
        onSubmit.setErrors(error.response.data);
      })
      .finally(() => {
        onSubmit.setSubmitting(false);
      });
  };

  const fileSelection = fileToUpload ? (
    <div className="inline-flex items-center justify-between bg-wf-input py-4 pl-3 pr-5">
      <Label className="text-base text-wf-900 font-noto">
        {fileToUpload.name}
      </Label>
      <button onClick={() => setFileToUpload(null)}>
        <img className="w-4 h-4" src={closeIcon} />
      </button>
    </div>
  ) : (
    <>
      <div
        {...getRootProps()}
        className="border-dashed border-2 border-wf-blue bg-wf-lightBlue py-4 px-8 space-y-2.5"
      >
        {/* TODO: check why the input needs an empty string or undefined */}
        <input value={undefined} {...getInputProps()} />
        <img src={dragNDropIcon} />
        <Label className="text-sm text-wf-mildBlue font-noto font-semibold">
          {t("partNumbers.wizard.pcbBoardInfoForm.dragNDropHint")}
        </Label>
      </div>
    </>
  );

  // If drawData was not fetched already, wait for it
  if (!drawData) return null;

  // Extract width and height from profile
  const {
    profile: {
      polygon: { bounds },
    },
  } = drawData;
  const width = bounds[1][0] - bounds[0][0];
  const height = bounds[1][1] - bounds[0][1];

  return (
    <div className="flex mx-auto border border-wf-300 mx-34">
      <Formik initialValues={{ width, height }} validationSchema={schema}>
        {(onSubmit) => (
          <div className="flex w-full flex-col">
            {!minimal && (
              <div className="flex border-b border-wf-300 bg-white px-8 py-4">
                <Label className="text-base text-wf-900 font-noto font-semibold">
                  {t("partNumbers.wizard.pcbBoardInfoForm.formTitle", {
                    currentStep: wizardStep,
                    wizardStepsCount: wizardStepsCount,
                  })}
                </Label>
              </div>
            )}
            <div className="flex flex-col px-8 py-8">
              <Label className="text-sm text-wf-800 font-noto font-semibold mb-2">
                {t("partNumbers.wizard.pcbBoardInfoForm.pcbDimensionsFields")}
              </Label>
              <div className="flex h-full mb-4">
                <Form
                  id="general-information-form"
                  data-test="general-information-form"
                >
                  <div className="inline-flex space-x-4">
                    <div className="flex flex-col">
                      <Field
                        className="w-full p-3 border-b border-wf-500 focus:outline-none bg-wf-input placeholder-gray-600"
                        placeholder={t(
                          "partNumbers.wizard.pcbBoardInfoForm.width"
                        )}
                        name={"width"}
                      />
                      <InputError name="width" />
                    </div>
                    <div className="flex flex-col">
                      <Field
                        className="w-full p-3 border-b border-wf-500 focus:outline-none bg-wf-input placeholder-gray-600"
                        placeholder={t(
                          "partNumbers.wizard.pcbBoardInfoForm.height"
                        )}
                        name={"height"}
                      />
                      <InputError name="height" />
                    </div>
                  </div>
                </Form>
              </div>
              <Label className="text-sm text-wf-800 font-noto font-semibold">
                {t("partNumbers.wizard.pcbBoardInfoForm.dragNDropTitle")}
              </Label>
              <Label className="text-sm text-wf-700 font-noto my-2">
                {t("partNumbers.wizard.pcbBoardInfoForm.dragNDropDescription")}
              </Label>
              {fileSelection}
            </div>
            <div className="flex justify-end bg-wf-input border-t border-wf-300 h-20 px-8 py-4">
              {!minimal && (
                <button
                  className="border-wf-900 border-2 px-3 mr-2"
                  onClick={() => handleSubmit(onSubmit, closeWizard)}
                >
                  {t(
                    "partNumbers.wizard.generalInformationForm.saveAsDraftButton"
                  )}
                </button>
              )}
              <Button
                onClick={() => handleSubmit(onSubmit, onCreationComplete)}
              >
                {t(
                  "partNumbers.wizard.generalInformationForm.saveAndProceedButton"
                )}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default PcbBoardInfoForm;
