import React, { useRef, useState, useEffect, useCallback } from "react";

/*
 * Hook to reuse whenever a togglable item is needed
 * An `options` parameter can be passed to specify open and close callbacks:
 * useTogglable({ openCb: () => setState('something'), closeCb: () => setState(null)})
 */
export const useTogglable = (options) => {
  const [isOpen, setState] = useState(false);

  const openCb = options?.openCb;
  const closeCb = options?.closeCb;

  const open = useCallback(() => {
    if (openCb) {
      openCb();
    }
    setState(true);
  }, []);
  const close = useCallback(() => {
    if (closeCb) {
      closeCb();
    }
    setState(false);
  }, []);
  const toggle = useCallback(() => {
    if (isOpen && closeCb) {
      closeCb();
    } else if (!isOpen && openCb) {
      openCb();
    }
    setState(!isOpen);
  }, [isOpen]);
  return { isOpen, open, close, toggle };
};

/*
 * Hook used to set and retrieve user token for authentication.
 */
export const useToken = () => {
  const setToken = (userToken) =>
    localStorage.setItem("token", JSON.stringify(userToken));
  const getToken = () => localStorage.getItem("token");
  const removeToken = () => localStorage.removeItem("token");

  return {
    setToken,
    getToken,
    removeToken,
  };
};

/*
 * Hook to trigger a function when an element outside
 * the referenced one is clicked.
 */
export const useOnClickOutside = (callback) => {
  const ref = useRef(null);
  const onClickOutside = React.useCallback(
    (e) => {
      if (ref.current && callback && !ref.current.contains(e.target)) {
        callback();
      }
    },
    [ref]
  );
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutside);
    document.addEventListener("touchstart", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
      document.removeEventListener("touchstart", onClickOutside);
    };
  }, [ref, onClickOutside]);
  return { ref };
};
