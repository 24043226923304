import React from "react";

import PropTypes from "prop-types";
import Label from "../Label";
import checkIcon from "url:../../assets/check.svg";

const WizardProgressComponent = ({
  stepNumber,
  label,
  currentPage,
  onClick,
  disabled,
}) => {
  const relativeIndex = currentPage - stepNumber;
  let circleStyle = "";
  let borderColor = "";

  switch (true) {
    case relativeIndex < 0:
      circleStyle = "bg-transparent border-wf-900 text-wf-900";
      borderColor = "border-wf-500";
      break;
    case relativeIndex === 0:
      circleStyle = "bg-wf-blue border-wf-blue text-white";
      borderColor = "border-wf-blue";
      break;
    case relativeIndex > 0:
      circleStyle = "bg-transparent border-wf-green";
      borderColor = "border-wf-blue";
  }

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`flex flex-col border-t-2 ${borderColor} w-51`}
    >
      <div className="flex flex-row mt-2">
        <div
          className={`flex items-center text-center rounded-full h-6 w-6 border ${circleStyle}`}
        >
          {relativeIndex > 0 ? (
            <img className="flex m-auto" src={checkIcon} />
          ) : (
            <div className="flex font-noto font-semibold text-xs m-auto">
              {stepNumber}
            </div>
          )}
        </div>
        <Label className="font-noto ml-3">{label}</Label>
      </div>
    </button>
  );
};

WizardProgressComponent.propTypes = {
  currentPage: PropTypes.number,
};

export default WizardProgressComponent;
